import React from "react";
import ReactDOM from "react-dom/client";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./styles/index.scss";
import 'primeicons/primeicons.css';
import { Main } from "./pages/Main";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <PrimeReactProvider>
        <Main />
      </PrimeReactProvider>
    </BrowserRouter>
  </Provider>
);
