import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { getAllSalesApi } from "../../http/settings";
import { ContentTop } from "../UI/ContentTop";
import { ContentFilter } from "../UI/ContentFilter";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { Image } from 'primereact/image';

export const InformationSale = () => {
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])

    const [filters, setFilters] = useState({
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [descriptionFilterValue, setDescriptionFilterValue] = useState('');

    useEffect(() => {
        getAllSalesApi()
        .then(res => setItems(res.data))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [setLoading, loading])

    const onDescriptionFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['description'].value = value;

        setFilters(_filters);
        setDescriptionFilterValue(value);
    };  

    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Акции</div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-app">
                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={descriptionFilterValue} onChange={onDescriptionFilterChange} placeholder="Поиск по описанию" />
                    </IconField>
                </div>
            </ContentFilter>
            </>
        )
    }

    const endDateView = (i) => {
        return <div>{new Date(i.endDate).toLocaleDateString("ru", {
            day: "numeric",
            month: "short",
            year: "numeric",})}</div>
    }

    const pictureView = (i) => {
        if(i.file) return <Image src={`${process.env.REACT_APP_API_URL}files/${i.file}`} zoomSrc={`${process.env.REACT_APP_API_URL}files/${i.file}`} alt="Image" width="80" height="60" preview />
    } 

    return (
        <div className="field">
            <DataTable filters={filters} dataKey="id" value={items.filter(i => i.status === "Действующая")} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
                <Column header="Название" field="name"/>
                <Column header="Компания" field="company" style={{width: "8%", textAlign: "center", fontWeight: "bold"}}/>   
                <Column header="Описание" field="description"/> 
                <Column header="Промокод" field="promo" style={{width: "8%", textAlign: "center", fontWeight: "bold"}}/>
                <Column header="Скидка" field="sum" style={{width: "5%", textAlign: "center", fontWeight: "bold", textDecoration: "underline"}}/>
                <Column header="Статус" field="status" style={{width: "8%", textAlign: "center", fontWeight: "bold"}}/>
                <Column body={endDateView} header="Дата окончания" field="endDate" style={{width: "8%", textAlign: "center", fontWeight: "bold"}}/>
                <Column body={pictureView} header="Промо" field="file" style={{width: "5%"}}/>
            </DataTable>
        </div>
    )
}