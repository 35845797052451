import React, { useEffect, useState } from "react";
import "./index.scss";

export const DateNow = () => {
  const [dateState, setDateState] = useState(new Date());
  
  
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  return (
    <div className="date">
      <p>
        {" "}
        {dateState.toLocaleDateString("ru", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
      </p>
      <p>
        {dateState.toLocaleString("ru", {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        })}
      </p>
    </div>
  );
};
