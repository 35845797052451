import { createAction, createReducer } from "@reduxjs/toolkit"

const state = {
    isAuth: false,
    user: null,
    allUsers: []
}

export const getAuth = createAction("GET_AUTH")
export const getUser = createAction("GET_USER")
export const getAllUsersState = createAction("GET_USERS")

export default createReducer(state, builder => {
    builder.addCase(getAuth, (state, action) => {
        state.isAuth = action.payload
    })
    builder.addCase(getUser, (state, action) => {
        state.user = action.payload
    })
    builder.addCase(getAllUsersState, (state, action) => {
        state.allUsers = action.payload
    })
})