import React, { useEffect, useState } from "react";
import './index.scss'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { createCompanyAdressApi, createCompanyCityApi, createCompanyStaffApi, deleteCompanyAdressApi, deleteCompanyApi, deleteCompanyCityApi, deleteCompanyStaffApi, editCompanyApi, getOneCompanyApi } from "../../http/companyApi";
import { ContentTop } from "../UI/ContentTop";
import { Button } from "primereact/button";
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { dateView, paymentStatus } from "../../utills/functions";

export const CompanyItem = () => {
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [company, setCompany] = useState(null) 
    const [contragent, setContragent] = useState(null)
    const [status, setStatus] = useState(null) 
    const [comment, setComment] = useState(null) 
    const [format, setFormat] = useState(null) 
    const [numberDogovor, setNumberDogovor] = useState(null) 
    const [costDelivery, setCostDelivery] = useState(null) 
    const [costMaster, setCostMaster] = useState(null)
    const [city, setCity] = useState([])
    const [adress, setAdress] = useState([])
    const [staff, setStaff] = useState([])
    const [payments, setPayments] = useState([])
    const [files, setFiles] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            if(id && loading) getOneCompanyApi(id)
            .then(res => {
                setCompany(res.data.company)
                setContragent(res.data.contragent)
                setStatus({name: res.data.status})
                setComment(res.data.comment)
                setFormat({name: res.data.format})
                setNumberDogovor(res.data.numberDogovor)
                setCostDelivery(res.data.costDelivery)
                setCostMaster(res.data.costMaster)
                setCity(res.data.city)
                setAdress(res.data.adress)
                setStaff(res.data.staff)
                setPayments(res.data.payments)
                setFiles(res.data.files)
            })
            .catch(e => alert(e)).finally(() => setLoading(false))
        }, 200);
    }, [id, loading])

    const saveCompany = () => {
        try {
          editCompanyApi(id, company, contragent, status.name, comment, format.name, numberDogovor, costDelivery, costMaster, city, adress, staff)
          setLoading(true)
        } catch (error) {
          alert(error)      
        }
    }

    const deleteCompany = () => {
        deleteCompanyApi(id, city, adress, staff)
        navigate(-1)
    }

    const changeCity = (key, value, id) => {
        setCity(city.map((i) => (i.id === id ? { ...i, [key]: value } : i)));
    };

    const changeAdress = (key, value, id) => {
        setAdress(adress.map((i) => (i.id === id ? { ...i, [key]: value } : i)));
    };

    const changeStaff = (key, value, id) => {
        setStaff(staff.map((i) => (i.id === id ? { ...i, [key]: value } : i)));
    };

    const createCity = () => {
        createCompanyCityApi("", id)
          .catch((e) => alert(e))
          .finally(() => setLoading(true))
    };
    const createAdress = () => {
        createCompanyAdressApi("", id)
          .catch((e) => alert(e))
          .finally(() => setLoading(true))
    };
    const createStaff = () => {
        createCompanyStaffApi("", "", id)
          .catch((e) => alert(e))
          .finally(() => setLoading(true))
    };
    const deleteCity = (i) => {
        deleteCompanyCityApi(i.id)
          .catch((e) => alert(e))
          .finally(() => setLoading(true))
    };
    const deleteAdress = (i) => {
        deleteCompanyAdressApi(i.id)
          .catch((e) => alert(e))
          .finally(() => setLoading(true))
    };
    const deleteStaff = (i) => {
        deleteCompanyStaffApi(i.id)
          .catch((e) => alert(e))
          .finally(() => setLoading(true))
    };

    const datePayment = (i) => {
        return <div>{dateView(i.createdAt)}</div>
    }

    const sumPayment = (i) => {
        return <div>{i.sum.toLocaleString("ru")} ₽</div>
    }

    const statusPayment = (i) => {
        return <div className={`table-payment-status table-payment-status-${paymentStatus(i.status)}`}>{i.status}</div>
    }

    const articlePayment = (i) => {
        return <div className="table-payment-article">{i.article}</div>
    }

    const filePayment = (i) => {
        return <div className="table-payment-file">
                    { i.file && <NavLink target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}>
                        <i className="pi pi-file" style={{ fontSize: '2rem', color: "black" }}></i>
                    </NavLink>}
                </div>
    }

    if(loading) {
        return <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration="2s" />
    }

    return (
        <div className="field">
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">{contragent}</div>
                    <div className="content-top-app">
                        <Button onClick={saveCompany} label="Сохранить"/>
                        <Button onClick={() => navigate(-1)} label="Выйти"/>
                        <Button onClick={deleteCompany} label="Удалить" style={{background: "red"}}/>
                    </div>
                </div>
            </ContentTop>

            <TabView>
                <TabPanel header="Профиль">
                    <div className="profile">
                        <div className="profile-column">
                            <div className="profile-row">
                                <h3 className="profile-row-title">Компания</h3>
                                <InputText value={company} onChange={(e) => setCompany(e.target.value)} />
                            </div>
                            <div className="profile-row">
                                <h3 className="profile-row-title">Контрагент</h3>
                                <InputText value={contragent} onChange={(e) => setContragent(e.target.value)} />
                            </div>
                            <div className="profile-row">
                                <h3 className="profile-row-title">Договор</h3>
                                <InputText value={numberDogovor} onChange={(e) => setNumberDogovor(e.target.value)} />
                            </div>
                            <div className="profile-row">
                                <h3 className="profile-row-title">Формат</h3>
                                <Dropdown value={format} onChange={(e) => setFormat(e.value)} options={[{name: "Аренда"}, {name: "Депозит"}, {name: "Промокод"}]} optionLabel="name" 
                                    placeholder="Select a Format" />
                            </div>
                            <div className="profile-row">
                                <h3 className="profile-row-title">Статус</h3>
                                <Dropdown value={status} onChange={(e) => setStatus(e.value)} options={[{name: "Новый"}, {name: "Действующий"}, {name: "Переговоры"}, {name: "Завершенный"}, {name: "Отмененный"}]} optionLabel="name" 
                                    placeholder="Select a Status" />
                            </div>
                            <div className="profile-row">
                                <h3 className="profile-row-title">Доставка</h3>
                                <InputText value={costDelivery} onChange={(e) => setCostDelivery(e.target.value)} />
                            </div>
                            <div className="profile-row">
                                <h3 className="profile-row-title">Мастер</h3>
                                <InputText value={costMaster} onChange={(e) => setCostMaster(e.target.value)} />
                            </div>
                            <div className="profile-row">
                                <h3 className="profile-row-title">Комментарий</h3>
                                <InputTextarea value={comment} onChange={(e) => setComment(e.target.value)} />
                            </div>
                        </div>
                        <div className="profile-column">
                            <div className="profile-title">
                                <h3>Отвественные</h3>
                                <Button onClick={() => createStaff()}>Добавить</Button>
                            </div>

                            {staff.map(i => {
                                return (
                                    <>
                                        <div className="profile-row">
                                            <InputText value={i.name} onChange={(e) => changeStaff("name", e.target.value, i.id)} />
                                            <InputText value={i.phone} onChange={(e) => changeStaff("phone", e.target.value, i.id)} />
                                            <Button onClick={() => deleteStaff(i)} style={{width: "7rem", backgroundColor: "red", borderColor: "red"}} icon="pi pi-times" />
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                        <div className="profile-column">
                            <div className="profile-title">
                                <h3>Адреса</h3>
                                <Button onClick={() => createAdress()}>Добавить</Button>
                            </div>

                            {adress.map(i => {
                                return (
                                    <>
                                        <div className="profile-row">
                                            <InputText value={i.name} onChange={(e) => changeAdress("name", e.target.value, i.id)} />
                                            <Button onClick={() => deleteAdress(i)} style={{width: "3rem", backgroundColor: "red", borderColor: "red"}} icon="pi pi-times" />
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                        <div className="profile-column">
                            <div className="profile-title">
                                <h3>Города</h3>
                                <Button onClick={() => createCity()}>Добавить</Button>
                            </div>

                            {city.map(i => {
                                return (
                                    <>
                                        <div className="profile-row">
                                            <InputText value={i.name} onChange={(e) => changeCity("name", e.target.value, i.id)} />
                                            <Button onClick={() => deleteCity(i)} style={{width: "3rem", backgroundColor: "red", borderColor: "red"}} icon="pi pi-times" />
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Счета">
                    <DataTable showGridlines stripedRows selectionMode="single" value={payments} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="createdAt" body={datePayment} header="Дата" style={{ width: '15%' }}></Column>
                        <Column field="name" header="Номер счёта" ></Column>
                        <Column field="sum" body={sumPayment} header="Сумма" style={{ width: '8%' }}></Column>
                        <Column field="status" body={statusPayment} header="Статус" style={{ width: '10%' }}></Column>
                        <Column field="article" body={articlePayment} header="Статья" style={{ width: '10%' }}></Column>
                        <Column field="file" body={filePayment} header="Файл" style={{ width: '3%' }}></Column>
                    </DataTable>
                </TabPanel>
                <TabPanel header="Документы">
                    <div className="company-documents">
                        <div className="profile-column">
                            <div className="profile-title">
                                <h3>Договор</h3> 
                            </div>
                            {files.filter(q => q.type === "Договор").map(i => {
                                return (
                                    <div className="profile-row">
                                        <div className="company-documents-item">
                                            <NavLink target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}>
                                                <i className="pi pi-file" style={{ fontSize: '2rem', color: "black" }}></i>
                                            </NavLink>
                                           <div className="company-documents-item-name">{i.name}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="profile-column">
                        <div className="profile-title">
                                <h3>Карточка</h3> 
                            </div>
                            {files.filter(q => q.type === "Карточка").map(i => {
                                return (
                                    <div className="profile-row">
                                        <div className="company-documents-item">
                                            <NavLink target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}>
                                                <i className="pi pi-file" style={{ fontSize: '2rem', color: "black" }}></i>
                                            </NavLink>
                                           <div className="company-documents-item-name">{i.name}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="profile-column">
                        <div className="profile-title">
                                <h3>Акты</h3> 
                            </div>
                            {files.filter(q => q.type === "Акт").map(i => {
                                return (
                                    <div className="profile-row">
                                        <div className="company-documents-item">
                                            <NavLink target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}>
                                                <i className="pi pi-file" style={{ fontSize: '2rem', color: "black" }}></i>
                                            </NavLink>
                                           <div className="company-documents-item-name">{i.name}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="profile-column">
                                <div className="profile-title">
                                    <h3>Шаблоны</h3> 
                                </div>
                                {files.filter(q => q.type === "Шаблон").map(i => {
                                    return (
                                        <div className="profile-row">
                                            <div className="company-documents-item">
                                                <NavLink target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}>
                                                    <i className="pi pi-file" style={{ fontSize: '2rem', color: "black" }}></i>
                                                </NavLink>
                                            <div className="company-documents-item-name">{i.name}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>    
                    </div>
                </TabPanel>
            </TabView>
        </div>
    )
}