import React from "react";
import './index.scss'
import { Route, Routes } from "react-router-dom";
import { CompanyNeedExpose } from "./needexpose";
import { CompanyNeedAtc } from "./needact";
import { CompanyPayments } from "./payments";
import { CompanyDocuments } from "./documents";

export const WCompany = () => {
    return (
        <Routes>
            <Route path="payments" element={<CompanyPayments/>}/>
            <Route path="need-payment" element={<CompanyNeedExpose/>}/>
            <Route path="need-act" element={<CompanyNeedAtc/>}/>
            <Route path="documents" element={<CompanyDocuments/>}/>
        </Routes>
    )
}