import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useHref, useNavigate } from "react-router-dom";
import { getAuth, getUser } from "../store/reducers/user";
import { check } from "../http/userApi";
import { NotFound } from "./NotFound";
import { Content } from "./Content";
import { Auth } from "./Auth";

export const Main = () => {
    const [loading, setLoading] = useState(true)
    const isAuth = useSelector(state => state.user.isAuth)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const www = useHref()
 
    useEffect(() => {
        if(isAuth && www === '/login') navigate("/")
        if(www === "/login" && !isAuth) localStorage.removeItem("token")
    }, [isAuth, navigate, www])

    useEffect(() => {
        if(localStorage.getItem("token")) {
            check()
            .then(data => {
                dispatch(getAuth(true))   
                dispatch(getUser(data))
            })
            .catch(e => {
                navigate('/login')
                dispatch(getUser(null))
                dispatch(getAuth(false))
                setLoading(false)
            }).finally(() => setLoading(false))
        } else {
            navigate('/login')
            dispatch(getUser(null))
            dispatch(getAuth(false))
            setLoading(false)
        }
    }, [isAuth, dispatch, navigate])

    if(loading) {
        return <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration="2s" />
    }

    return (
        <Routes>
            {isAuth && <Route path={"/*"} element={<Content/>} /> }
            {!isAuth && <Route path={"/login"} element={<Auth/>} />}
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}