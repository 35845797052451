import React from "react";
import './index.scss'
import { Route, Routes } from "react-router-dom";
import { ReportCompany } from "./company";

export const Reports = () => {
    return (
        <Routes>
            <Route path="company" element={<ReportCompany/>}/>
        </Routes>
    )
}