import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { ContentTop } from "../UI/ContentTop";
import { ContentFilter } from "../UI/ContentFilter";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { createManagerTablesApi, deleteManagerTablesApi, editManagerTablesApi, getAllManagerTablesApi } from "../../http/managerTables";
import { dateView, getRange, managerTableStatus } from "../../utills/functions";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { NavLink } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode } from "primereact/api";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dialog } from "primereact/dialog";
import { InputMask } from "primereact/inputmask";

export const ManagerOrders = () => {
    const [loading, setLoading] = useState(true)
    const user = useSelector((state) => state.user.user);
    const [items, setItems] = useState([])
    const [value, setValue] = useState(user.role === "ADMIN" ? {name: "Все заявки"} : {name: "Мои заявки"})
    const [dates, setDates] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);
    const toast = useRef(null);
    
    const [filters, setFilters] = useState({
        phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        dogovor: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [phoneFilterValue, setPhoneFilterValue] = useState('');
    const [dogovorFilterValue, setDogovorFilterValue] = useState('');
    const [createDialog, setCreateDialog] = useState(false)
    const [createItem, setCreateItem] = useState("")
    const [createComment, setCreateComment] = useState("")

    useEffect(() => {
        getAllManagerTablesApi(true, null, null, null, getRange(dates), value.name === "Мои заявки" ? user.id : null)
        .then(response => {
            setItems(response.data)
        })
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [loading, setLoading, user, dates, value])

    const onPhoneFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['phone'].value = value;

        setFilters(_filters);
        setPhoneFilterValue(value);
    };

    const onDogovorFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['dogovor'].value = value;

        setFilters(_filters);
        setDogovorFilterValue(value);
    };

    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Заявки</div>
                    <div className="content-top-app">
                        <Button onClick={create} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {items.length} шт.</div>
                <div className="content-filter-app">
                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={phoneFilterValue} onChange={onPhoneFilterChange} placeholder="Поиск по номеру" />
                    </IconField>
                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={dogovorFilterValue} onChange={onDogovorFilterChange} placeholder="Поиск по договору" />
                    </IconField>
                    <Dropdown value={value} onChange={(e) => {
                        setValue(e.value)
                        setLoading(!loading)
                    }} options={[{name: "Мои заявки"}, {name: "Все заявки"}]} optionLabel="name" 
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" dateFormat="dd/mm/yy" readOnlyInput hideOnRangeSelection />
                </div>
            </ContentFilter>
            </>
        )
    }

    const create = () => {
        setCreateDialog(true)
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;

        if(user.id === newData.userId || user.role === "ADMIN") {
            try {
                editManagerTablesApi(newData.id, newData.phone, newData.dogovor, newData.sum, newData.status, newData.comment, newData.strahovka, newData.yandex)
                toast.current.show({severity:'success', summary: 'Успешно!', detail: "Заявка изменена!", life: 3000});
                setLoading(!loading)
            } catch (error) {
                toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
            }
        } else {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: "Вам запрещено редактировать чужие заявки!", life: 3000});
            return
        }
    };

    const dateOrder = (i) => {
        return <div>{dateView(i.createdAt)} / {i.managerName}</div>
    }

    const sumOrder = (i) => {
        if(i.sum) return <div style={{textAlign:"center"}}>{Number(i.sum).toLocaleString("ru")} ₽</div>
    }

    const statusOrder = (i) => {
        return <div className={`manager-status-table manager-status-table-${managerTableStatus(i.status)}`}>{i.status}</div>
    }

    const strahovkaView = (i) => {
        return <div style={{textAlign: "center"}}><Checkbox disabled checked={i.strahovka ? true : false}></Checkbox></div>
    }   
    
    const yandexView = (i) => {
        return <div style={{textAlign: "center"}}><Checkbox disabled checked={i.yandex ? true : false}></Checkbox></div>
    }

    const phoneView = (i) => {
        return  <div style={{display: "flex", gap: "1rem"}}>
                    <NavLink style={{color: "blue", textDecoration: "underline"}} target="_blank" rel="noreferrer" to={`https://rent.electrozona.ru/contracts/?keyword_type=phone&keyword=${i.phone}`}>
                        {i.phone}
                    </NavLink>

                    <div style={{display: "flex", alignItems: "center"}}>
                        <NavLink target="_blank" rel="noreferrer" to={`https://wa.me/${i.phone.replace(/\D/g, '')}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-whatsapp" viewBox="0 0 16 16">
                                <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                            </svg>
                        </NavLink>
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <NavLink target="_blank" rel="noreferrer" to={`https://t.me/+${i.phone.replace(/\D/g, '')}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-telegram" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
                            </svg>
                        </NavLink>
                    </div>
                </div>
                
    }

    const phoneEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const dogovorEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const sumEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const statusEditor = (options) => {
        const arr = [{name: "Новый"}, {name: "Выполнен"}, {name: "Отменен"}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    };

    const strahovkaEditor = (options) => {
        return <div style={{textAlign: "center"}}><Checkbox checked={options.value} onChange={(e) => options.editorCallback(e.checked)}></Checkbox></div>
    }   
    
    const yandexEditor = (options) => {
        return <div style={{textAlign: "center"}}><Checkbox checked={options.value} onChange={(e) => options.editorCallback(e.checked)}></Checkbox></div>
    }

    const commentEditor = (options) => {
        return <InputTextarea style={{width: "100%"}} value={options.value} onChange={e => options.editorCallback(e.target.value)}/>
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                deleteManagerTablesApi(i.id)
                  setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить заказ?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const createItemButton = async () => {
        try {
            // let item = items.find(i => i.phone === createItem)
            let item = await getAllManagerTablesApi(true, null, null, null, getRange(dates), null)
            .then(response => response.data.find(e => e.phone === createItem))
            .catch(e => alert(e))

            if(item) {
                toast.current.show({severity:'error', summary: 'Ошибка', detail: `Заявка на этого клиента уже существует в базе данных текущего месяца, созданная менеджером ${item.managerName} от ${new Date(item.createdAt).toLocaleDateString()}`, life: 3000});
            } else {
                toast.current.show({severity:'success', summary: 'Успешно', detail: `Заявка успешно создана`, life: 3000});
                createManagerTablesApi(user.name, createItem, null, null, "Новый", createComment, false, false, true, false, false, false, false, user.id)
                setCreateDialog(false)
                setLoading(!loading)
            }
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: error, life: 3000});
        }
    }    

    return (
        <div className="field">
            <Toast ref={toast} position="bottom-center"/>
            <ConfirmDialog />
            <DataTable filters={filters} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable  paginator rows={25} rowsPerPageOptions={[25, 50, 100]} tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
                <Column header={"Дата/Менеджер"} body={dateOrder} style={{width: "18%"}}/>
                <Column editor={(options) => phoneEditor(options)} header={"Номер клиента"} body={phoneView} field="phone" style={{width: "10%", textAlign: "center"}}/>
                <Column editor={(options) => dogovorEditor(options)} header={"Договор"} field="dogovor" style={{width: "7%", textAlign: "center"}}/>
                <Column editor={(options) => sumEditor(options)} header={"Сумма"} body={sumOrder} field="sum" style={{width: "7%"}}/>
                <Column editor={(options) => statusEditor(options)} header={"Статус"} body={statusOrder} field="status" style={{width: "8%"}}/>
                <Column editor={(options) => strahovkaEditor(options)} header={"Страховка"} field="strahovka" body={strahovkaView} style={{width: "5%"}}/>
                <Column editor={(options) => yandexEditor(options)} header={"Яндекс"} field="yandex" body={yandexView} style={{width: "5%"}}/>
                <Column editor={(options) => commentEditor(options)} header={"Комментарий"} field="comment" style={{width: ""}}/>
                <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                {user.role === "ADMIN" && <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>}
            </DataTable>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={createDialog} onHide={() => {if (!createDialog) return; setCreateDialog(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="phone" className="font-bold">
                            Номер клиента
                        </label>
                        <InputMask value={createItem} onChange={e => setCreateItem(e.target.value)} id="phone" mask="99999999999" placeholder="7(999) 999-9999"></InputMask>
                    </div>

                    <div className="create-row">
                        <label htmlFor="comment" className="font-bold">
                            Комментарий
                        </label>
                        <InputTextarea id="comment" value={createComment} onChange={e => setCreateComment(e.target.value)} />
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>
        </div>
    )
}