import React, { useEffect, useState } from "react";
import "./index.scss"
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { getAuth, getUser } from "../../../store/reducers/user";
import {  DateNow } from "../Date";
import { PanelMenu } from "primereact/panelmenu";
import { sources } from "../../../utills/menus";
import { linkAdmin, linkManager } from "../../../utills/links";
import { NavLink } from "react-router-dom";
import { getAllTaskApi } from "../../../http/task";

export const Sidebar = () => {
    const [loading, setLoading] = useState(true)
    const [tasks, setTasks] = useState([])
    const user = useSelector(state => state.user.user)
    const dispatch = useDispatch()
    const exit = () => {
        dispatch(getAuth(false))
        dispatch(getUser(null))
        localStorage.clear("token")
    }

    useEffect(() => {
        getAllTaskApi()
        .then(res => setTasks(res.data.filter(i => {
            if(i.complete.find(e => e.userId === user.id)) {
                return i
            }
        })))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [user, loading, setLoading])

    return (
        <div className="sidebar">
                <DateNow/>
                <div className="sidebar-links">
                    <PanelMenu model={user.role === "ADMIN" ? linkAdmin(tasks.length) : linkManager(tasks.length)} style={{fontSize: "1.6rem"}} className="ww" />
                </div>

                <div className="sidebar-sites">
                    <div className="card flex justify-content-center">
                        <PanelMenu model={sources} className="w-full md:w-20rem" />
                    </div>
                </div>

                <div className="sidebar-profile">
                    <span> <i className="pi pi-user" style={{marginRight: "5px"}}></i> {user.name}</span>
                    <Button onClick={exit} icon="pi pi-sign-out" label="Выйти" />
                    {user.role === "ADMIN" && <NavLink to={"/settings"}><i className="pi pi-cog" style={{fontSize: "1.4rem"}}></i></NavLink>}
                </div>
        </div>
    )
}