import { $host } from "./index";

export const createManagerTablesApi = async (managerName, phone, dogovor, sum, status, comment, strahovka, yandex, order, prod, debt, red, ended, userId) => {
  const { data } = await $host.post("api/manager-tables", {managerName, phone, dogovor, sum, status, comment, strahovka, yandex, order, prod, debt, red, ended, userId });
  return data;
};

export const getAllManagerTablesApi = async (order, prod, debt, red, range, userId) => {
  const data = await $host.get("api/manager-tables", {
    params: {
      order: order, 
      prod: prod, 
      debt: debt, 
      red: red, 
      range: range, 
      userId: userId
    }
  });
  return data;
};

export const getOneManagerTablesApi = async (id) => {
  const data = await $host.get(`api/manager-tables/${id}`);
  return data;
};

export const deleteManagerTablesApi = async (id) => {
  const data = await $host.delete(`api/manager-tables/${id}`);
  return data;
};

export const cancelManagerTablesApi = async (id, status) => {
  const data = await $host.patch(`api/manager-tables/${id}`, {status});
  return data;
};

export const editManagerTablesApi = async (id, phone, dogovor, sum, status, comment, strahovka, yandex) => {
  const data = await $host.put(`api/manager-tables/${id}`, {phone, dogovor, sum, status, comment, strahovka, yandex});
  return data;
};
