import { $host } from "./index";

// PRICE

export const createPriceApi = async (name, sum_one, sum_two, sum_three, sum_four, sum_five) => {
  const { data } = await $host.post("api/price", { name, sum_one, sum_two, sum_three, sum_four, sum_five });
  return data;
};

export const getAllPriceApi = async () => {
  const data = await $host.get("api/price");
  return data;
};

export const getOnePriceApi = async (id) => {
  const data = await $host.get(`api/price/${id}`);
  return data;
};

export const deletePriceApi = async (id) => {
  const data = await $host.delete(`api/price/${id}`);
  return data;
};

export const editPriceApi = async (id, name, sum_one, sum_two, sum_three, sum_four, sum_five) => {
  const data = await $host.put(`api/price/${id}`, {name, sum_one, sum_two, sum_three, sum_four, sum_five});
  return data;
};

export const createCityApi = async (city, adress, metro, time, employer, phone, code) => {
    const { data } = await $host.post("api/city", { city, adress, metro, time, employer, phone, code });
    return data;
  };
  
  export const getAllCityApi = async () => {
    const data = await $host.get("api/city");
    return data;
  };
  
  export const getOneCityApi = async (id) => {
    const data = await $host.get(`api/city/${id}`);
    return data;
  };
  
  export const deleteCityApi = async (id) => {
    const data = await $host.delete(`api/city/${id}`);
    return data;
  };
  
  export const editCityApi = async (id, city, adress, metro, time, employer, phone, code) => {
    const data = await $host.put(`api/city/${id}`, {city, adress, metro, time, employer, phone, code});
    return data;
  };

  export const createGuidesApi = async (file) => {
    const { data } = await $host.post("api/guides",  file );
    return data;
  };
  
  export const getAllGuidesApi = async () => {
    const data = await $host.get("api/guides");
    return data;
  };
  
  export const getOneGuidesApi = async (id) => {
    const data = await $host.get(`api/guides/${id}`);
    return data;
  };
  
  export const editGuidesApi = async (id, name, file) => {
    const data = await $host.put(`api/guides/${id}`, { name, file});
    return data;
  };
  
  export const deleteGuidesApi = async (id, file) => {
    const data = await $host.delete(`api/guides/${id}`, {
      params: {
          file
      }
    });
    return data;
  };
  