import { $host } from "./index";

export const createCompanyApi = async (company, contragent, status, comment, format, numberDogovor, costDelivery, costMaster, city, adress, staff) => {
  const { data } = await $host.post("api/company", { company, contragent, status, comment, format, numberDogovor, costDelivery, costMaster, city, adress, staff });
  return data;
};

export const createCompanyCityApi = async (name, companyId) => {
  const { data } = await $host.post("api/company/city", { name, companyId });
  return data;
};

export const createCompanyAdressApi = async (name, companyId) => {
  const { data } = await $host.post("api/company/adress", { name, companyId });
  return data;
};

export const createCompanyStaffApi = async (name, phone, companyId) => {
  const { data } = await $host.post("api/company/staff", { name, phone, companyId });
  return data;
};

export const getAllCompanyApi = async () => {
  const data = await $host.get("api/company");
  return data;
};

export const getOneCompanyApi = async (id) => {
  const data = await $host.get(`api/company/${id}`);
  return data;
};

export const deleteCompanyApi = async (id, city, adress, staff) => {
  const data = await $host.delete(`api/company/${id}`, {
    params: {
      city, adress, staff
    }
  });
  return data;
};

export const editCompanyApi = async (id, company, contragent, status, comment, format, numberDogovor, costDelivery, costMaster, city, adress, staff) => {
  const data = await $host.put(`api/company/${id}`, {company, contragent, status, comment, format, numberDogovor, costDelivery, costMaster, city, adress, staff});
  return data;
};

export const deleteCompanyCityApi = async (id) => {
  const data = await $host.delete(`api/company/city/${id}`);
  return data;
};

export const deleteCompanyAdressApi = async (id) => {
  const data = await $host.delete(`api/company/adress/${id}`);
  return data;
};

export const deleteCompanyStaffApi = async (id) => {
  const data = await $host.delete(`api/company/staff/${id}`);
  return data;
};
