import { $host } from "./index";

export const createCommentClientApi = async (userName,phoneClient, model, comment) => {
  const { data } = await $host.post("api/comment-client", { userName, phoneClient, model, comment });
  return data;
};

export const getAllCommentClientApi = async (range) => {
  const data = await $host.get("api/comment-client", {
    params: {
      range
    }
  });
  return data;
};

export const getOneCommentClientApi = async (id) => {
  const data = await $host.get(`api/comment-client/${id}`);
  return data;
};

export const deleteCommentClientApi = async (id) => {
  const data = await $host.delete(`api/comment-client/${id}`);
  return data;
};

export const editCommentClientApi = async (id, phoneClient, model, comment) => {
  const data = await $host.put(`api/comment-client/${id}`, {phoneClient, model, comment});
  return data;
};
