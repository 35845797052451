import React, { useEffect, useRef, useState } from "react";
import './index.scss'
import { createTaskApi, deleteTaskApi, getAllTaskApi, statusTaskApi } from "../../http/task";
import { DataTable } from "primereact/datatable";
import { ContentTop } from "../UI/ContentTop";
import { Button } from "primereact/button";
import { ContentFilter } from "../UI/ContentFilter";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { getAllUsers } from "../../http/userApi";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { getAllTaskPriority } from "../../http/settings";
import { Toast } from "primereact/toast";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useSelector } from "react-redux";
import { Column } from "primereact/column";
import { dateView } from "../../utills/functions";

export const Task = () => {
    const toast = useRef(null);
    const [users, setUsers] = useState([])
    const user = useSelector((state) => state.user.user);
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [priorities, setPriorities] = useState(null)
    const [visible, setVisible] = useState(false)
    const [description, setDescription] = useState("")
    const [priority, setPriority] = useState(null)
    const [endDate, setEndDate] = useState();
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [value, setValue] = useState({name: "Исполнитель"})
    const [statusFilter, setStatusFilter] = useState({name: "Активные"})

    useEffect(() => {
        getAllTaskApi(statusFilter.name)
        .then(res => setItems(res.data.filter(i => {
            if(value.name === "Назначил") {
                return i.appointId === user.id
            } else if(value.name === "Исполнитель") {
                if(i.complete.find(e => e.userId === user.id)) {
                    return i
                }
            } else {
                return i
            }
        })))
        .catch(e => alert(e))
        .finally(() => setLoading(false))

        getAllUsers()
        .then(res => setUsers(res.data.filter(i => i.status === "Работает")))
        .catch(e => alert(e))
        .finally(() => setLoading(false))

        getAllTaskPriority()
        .then(res => setPriorities(res.data))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [loading, setLoading, user, value, statusFilter])

    const headerTable = (i) => {
        let arr
        if(user.position === "Руководитель" || user.position === "Администратор") {
            arr = [{name: "Все"}, {name: "Исполнитель"}, {name: "Назначил"}]
        } else {
            arr = [{name: "Исполнитель"}, {name: "Назначил"}]
        }

        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Задачи</div>
                    <div className="content-top-app">
                        <Button onClick={() => setVisible(true)} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {items.length} шт.</div>
                <div className="content-filter-app">
                    <Dropdown value={statusFilter} onChange={(e) => {
                        setStatusFilter(e.value)
                        setLoading(!loading)
                    }} options={[{name: "Активные"}, {name: "Завершенные"}]} optionLabel="name" 
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>
                    
                    <Dropdown value={value} onChange={(e) => {
                        setValue(e.value)
                        setLoading(!loading)
                    }} options={arr} optionLabel="name" 
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>
                </div>
            </ContentFilter>
            </>
        )
    }

    const userTemplate = (option) => {
        return (
            <div style={{fontSize: "1.4rem"}}>
                <div>{option.name}</div>
                <Tag style={{fontSize: "1rem"}} severity="success" value={option.position}></Tag>
            </div>
        );
    };

    const priorityTemplate = (option) => {
        return (
            <Tag style={{fontSize: "1.4rem", backgroundColor: `#${option.color}`}}>{option.name}</Tag>
        )
    }

    const selectedPriorityTemplate = (option, props) => {
        if(option) return (
            <Tag style={{fontSize: "1.4rem", backgroundColor: `#${option.color}`}}>{option.name}</Tag>
        )
        return <span>{props.placeholder}</span>;
    }

    const createItemButton = () => {
        try {
            createTaskApi(Number(user.id), description, priority.name, "Новая", new Date(endDate).toISOString(), JSON.stringify(selectedUsers))
            toast.current.show({severity:'success', summary: 'Успешно', detail: `Задача успешно создана`, life: 3000});
            setVisible(false)
            setLoading(!loading)
        } catch (error) {
            alert(error)
        }
    }

    const dataView = (i) => {
        return <div>{dateView(i.createdAt)}</div>
    }

    const appointView = (i) => {
        let item = users?.filter(q => q.id === i.appointId)
        
        if(item) return (
            <div style={{display: "flex", gap: ".4rem", flexDirection: "column"}}>
                {item[0]?.name && <div style={{background: "orange", maxWidth: "fit-content", padding: ".2rem .7rem", borderRadius: ".6rem", color: "white"}}>{item[0]?.name}</div>}
                {/* {item[0]?.position && <div style={{ maxWidth: "fit-content", fontStyle: "italic", fontSize: "1.2rem"}}>{item[0]?.position}</div>} */}
            </div>
        )
    }

    const pickColor = (color) => {
        let item = priorities?.filter(i => i.name === color)
        if(item) return item[0]?.color 
    }

    const priorityView = (i) => {
        return <Tag style={{fontSize: "1.4rem", padding: ".2rem 1rem", backgroundColor: `#${pickColor(i.priority)}`}}>{i.priority}</Tag>
    }

    const completeView = (i) => {
        if(i.complete.length !== 0) {
            return (
                <div style={{display: "flex", gap: ".5rem", flexWrap: "wrap"}}>
                    {i.complete.map(qw => {
                        let item = users?.filter(q => q.id === qw.userId)
                        return (
                            <div style={{background: "grey", maxWidth: "fit-content", padding: ".2rem .7rem", borderRadius: ".6rem", color: "white"}}>
                                {item[0]?.name}
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    const endDateView = (i) => {
        return <span style={{textDecoration: "underline", fontWeight: "bold"}}>
        {new Date(i.endDate).toLocaleDateString("ru", {
            day: "numeric",
            month: "short",
            year: "numeric",})}
    </span> 
    }

    const statusView = (i) => {
        if(i.status === "Новая") return <Button onClick={() => {
            statusTaskApi(i.id, "В работе")
            setLoading(true)
        }} style={{fontSize: "1.4rem", padding: ".2rem .7rem"}}>В работу</Button>
        if(i.status === "В работе") return <Button onClick={() => {
            statusTaskApi(i.id, "Завершено")
            setLoading(true)
        }} style={{fontSize: "1.4rem", padding: ".2rem .7rem"}} severity="success">Завершить</Button>
        if(i.status === "Завершено") return (
            <div style={{display: "flex", flexDirection: "column", gap: ".4rem"}}>
                <div style={{background: "green", maxWidth: "fit-content", padding: ".2rem .7rem", borderRadius: ".6rem", color: "white"}}>{i.status}</div>
                {user.role === "ADMIN" &&  <Button onClick={() => {
                    statusTaskApi(i.id, "Новая")
                    setLoading(true)
                }} style={{fontSize: "1.4rem", padding: ".2rem .7rem"}}>Возобновить</Button>}
            </div>
        )
    }

    const descriptionView = (i) => {
        return <div className="descriptionView" >{i.description}</div>
    }

    const deleteTable = (i) => {
        if(i.appointId === user.id || user.position === "Руководитель" || user.position === "Администратор") {

            return <i onClick={() => {
                const accept = () => {
                    try {
                      deleteTaskApi(i.id, i.complete)
                      setLoading(!loading)
                    } catch (error) {
                      alert(error)
                    }
                  };
              
                  const reject = () => {
                      return
                  };
          
                  confirmDialog({
                    message: 'Вы действительно хотите удалить задачу?',
                    header: 'Удаление',
                    icon: 'pi pi-info-circle',
                    defaultFocus: 'reject',
                    acceptClassName: 'p-button-danger',
                    position: 'center',
                    accept,
                    reject
                });
                  
            }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
        } else {
            return null
        }      
    }

    const allowEdit = (rowData) => {
        if(rowData.appointId === user.id || user.position === "Руководитель" || user.position === "Администратор") {
            return rowData
        } else {
            return null
        }
    };

    return (
        <div className="field">
            <Toast ref={toast} position="bottom-center"/>
            <ConfirmDialog />
            <DataTable editMode="row" dataKey="id" value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable  paginator rows={25} rowsPerPageOptions={[25, 50, 100]} tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
                <Column body={dataView} header="Дата/Сотрудник" field="createdAt" style={{width: "13%"}}/>
                <Column body={appointView} header="Назначил" field="appointId" style={{width: "10%"}}/>
                <Column body={completeView} header="Исполнители" field="complete" style={{width: "15%"}}/>
                <Column body={descriptionView} header="Описание" field="description"/>
                <Column body={statusView} header="Статус" field="status" style={{width: "8%", textAlign: "center", verticalAlign: "middle"}}/>
                <Column body={priorityView} header="Приоритет" field="priority" style={{width: "8%", textAlign: "center", verticalAlign: "middle"}}/>
                <Column body={endDateView} header="Срок" field="endDate" style={{width: "10%", textAlign: "center"}}/>
                <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>
            </DataTable>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create">

                    <div className="create-row">
                        <label htmlFor="description" className="font-bold">
                            Описание
                        </label>
                        <InputTextarea value={description} onChange={e => setDescription(e.target.value)} id="description"></InputTextarea>
                    </div>

                    <div className="create-row">
                        <label htmlFor="description" className="font-bold">
                            Выбрать исполнителей
                        </label>
                        <MultiSelect value={selectedUsers} onChange={(e) => setSelectedUsers(e.value)} options={users} optionLabel="name" 
                        placeholder="Сотрудники" display="chip" maxSelectedLabels={10} itemTemplate={userTemplate} style={{fontSize: "1.4rem"}} />
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Приоритет
                        </label>
                        <Dropdown
                            value={priority}
                            options={priorities}
                            onChange={(e) => setPriority(e.target.value)}
                            optionLabel="name"
                            placeholder="Приоритет"
                            style={{width: "100%"}}
                            itemTemplate={priorityTemplate}
                            valueTemplate={selectedPriorityTemplate}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="comment" className="font-bold">
                            Срок до
                        </label>
                            <Calendar
                                id="buttondisplay"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                dateFormat="dd MM yy"
                            />
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>
        </div>
    )
}