import React, { useEffect, useRef, useState } from "react";
import { ContentTop } from "../UI/ContentTop";
import { getAllManagerTablesApi } from "../../http/managerTables";
import { getRange } from "../../utills/functions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Calendar } from "primereact/calendar";
import { ContentFilter } from "../UI/ContentFilter";
import { Button } from "primereact/button";
import generatePDF from "react-to-pdf";

export const ManagerStatistics = () => {
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [stats, setStats] = useState([])
    const targetRef = useRef();
    const [dates, setDates] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);

    useEffect(() => {
        getAllManagerTablesApi(null, null, null, null, getRange(dates), null)
        .then(response => {
            setItems(response.data)
        })
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [setLoading, loading, dates])

    useEffect(() => {
        let newData = items.map((item) => 
            Object.assign(
                {},
                item,
                item.status === "Новый" && item.order ? {newOrder: 1} : {newOrder: 0},
                item.status === "Выполнен" && item.order ? {doneOrder: 1} : {doneOrder: 0},
                item.status === "Отменен" && item.order ? {canceledOrder: 1} : {canceledOrder: 0},

                item.order && item.status === "Выполнен" ? {sumOrder: item.sum} : {sumOrder: 0},
                item.debt && item.status === "Выполнен" ? {sumDebt: item.sum} : {sumDebt: 0},
                item.prod && item.status === "Выполнен" ? {sumProd: item.sum} : {sumProd: 0},
                item.red && item.status === "Выполнен" ? {sumRed: item.sum} : {sumRed: 0},
                item.status === "Выполнен" ? {sum: item.sum} : {sum: 0},

                item.prod && item.status === "Выполнен" ? {prod: item.prod} : {prod: 0},
                item.debt && item.status === "Выполнен" ? {debt: item.debt} : {debt: 0},
                item.red && item.status === "Выполнен" ? {red: item.red} : {red: 0},

                item.strahovka && item.status === "Выполнен" ? {strahovka: item.strahovka} : {strahovka: 0},
                item.yandex && item.status === "Выполнен" ? {yandex: item.yandex} : {yandex: 0},
                item.ended && item.status === "Выполнен" ? {ended: item.ended} : {ended: 0},
            )
        )

        const res = {};
        newData.forEach(({ userId, managerName, comment, createdAt, dogovor, phone, id, status, updatedAt, ...dates }) => {
             const item = res[userId] || { userId, managerName};

             Object.keys(dates).forEach(date => {
                  item[date] = (item[date] || 0) + Number(dates[date]);
             });

             res[userId] = { ...item };
        });

        setStats(Object.values(res))
      }, [items])

      const thisTotal = () => {
        let total = 0;

        for (let stat of stats) {
            total += stat.sum;
        }
        
        return Number(total).toLocaleString("ru") + " ₽";
    };

      const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Всего:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
                <Column footer={thisTotal} footerStyle={{ textAlign: 'center' }}/>
            </Row>
        </ColumnGroup>
    );

    const sumOrderView = (i) => {
        return <div>{Number(i.sumOrder).toLocaleString("ru") + " ₽"}</div>
    }

    const sumProdView = (i) => {
        return <div>{Number(i.sumProd).toLocaleString("ru") + " ₽"}</div>
    }

    const sumDebtView = (i) => {
        return <div>{Number(i.sumDebt).toLocaleString("ru") + " ₽"}</div>
    }

    const sumRedView = (i) => {
        return <div>{Number(i.sumRed).toLocaleString("ru") + " ₽"}</div>
    }

    const sumView = (i) => {
        return <div>{Number(i.sum).toLocaleString("ru") + " ₽"}</div>
    }

    const MCView = (i) => {
        let value = (i.doneOrder * 120) + (i.prod * 40) + (i.debt * 50) + (i.red * 300) + (i.strahovka * 90) + (i.yandex * 20)
        return <div>{value * 1.2}</div>
    }

    return (
        <div className="field">
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Статистика</div>
                </div>
            </ContentTop>
            <ContentFilter>
                <div className="content-filter-app">
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" dateFormat="dd/mm/yy" readOnlyInput hideOnRangeSelection />
                    <Button type="button" icon="pi pi-file-pdf" severity="warning" onClick={() => generatePDF(targetRef, {filename: 'manager.pdf'})} data-pr-tooltip="PDF" />
                </div>
            </ContentFilter>
            <div ref={targetRef} className="tables">
                <DataTable dataKey="userId" value={stats} showGridlines stripedRows selectionMode="single" style={{width: "90rem"}}>
                    <Column header="Сотрудник" field="managerName" style={{width: "20%"}}/>
                    <Column  header="Заяв." field="doneOrder" style={{width: "10%", textAlign: "center"}}/>
                    <Column  header="Прод." field="prod" style={{width: "10%", textAlign: "center"}}/>
                    <Column  header="Задл." field="debt" style={{width: "10%", textAlign: "center"}}/>
                    <Column  header="Прос." field="red" style={{width: "10%", textAlign: "center"}}/>
                    <Column  header="Янд." field="yandex" style={{width: "10%", textAlign: "center"}}/>
                    <Column  header="Стр." field="strahovka" style={{width: "10%", textAlign: "center"}}/>
                    <Column body={MCView}  header="MC" field="value" style={{width: "20%", textAlign: "center"}}/>
                </DataTable>

                <DataTable dataKey="userId" value={stats} footerColumnGroup={footerGroup} showGridlines stripedRows selectionMode="single" style={{width: "70rem"}}>
                    <Column header="Сотрудник" field="managerName" style={{width: "30%"}}/>
                    <Column body={sumOrderView} header="Заяв." field="sumOrder" style={{width: "10%", textAlign: "center"}}/>
                    <Column body={sumProdView} header="Прод." field="sumProd" style={{width: "10%", textAlign: "center"}}/>
                    <Column body={sumDebtView} header="Задл." field="sumDebt" style={{width: "10%", textAlign: "center"}}/>
                    <Column body={sumRedView} header="Прос." field="sumRed" style={{width: "10%", textAlign: "center"}}/>
                    <Column body={sumView} header="Общ." field="sum" style={{width: "10%", textAlign: "center"}}/>
                </DataTable>
            </div>
        </div>
    )
}