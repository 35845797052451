import {$authHost, $host} from "./index";
import { jwtDecode } from "jwt-decode";

export const login = async (login, password) => {
    const {data} = await $host.post('api/user/login', {login, password})
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const check = async () => {
    const {data} = await $authHost.get('api/user/auth' )
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const registr = async (login, password, role, position, status, name) => {
    const {data} = await $host.post('api/user/registration', {login, password, role, position, status, name})
    return data
}

export const getAllUsers = async () => {
    const data = await $host.get('api/user')
    return data
}

export const getOneUser = async (id) => {
    const data = await $host.get(`api/user/${id}`)
    return data
}

export const deleteUser = async (id) => {
    const data = await $host.delete(`api/user/${id}`)
    return data
}

export const editUser = async (id, login,  role, position, status, name) => {
    const data = await $host.put(`api/user/${id}`, {login,  role, position, status, name})
    return data
}