import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useRef, useState } from "react";
import { createCommentClientApi } from "../../http/comments";
import { Toast } from "primereact/toast";
import { modelsInfo } from "../../utills/constants";

export const CreateComment = ({loading, setLoading, user, setShow}) => {
  const toast = useRef(null);
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [model, setModel] = useState("")
  const create = () => {
    if(!phone || !model || !comment) {
       return toast.current.show({severity:'error', summary: 'Ошибка', detail: "Нужно заполнить все поля", life: 3000});
    }
      
      let userName = user.name
      createCommentClientApi(userName, phone, model.name, comment)
      setLoading(!loading)
      setPhone("")
      setModel("")
      setComment("")
      setShow(false)
  };

  return (
    <div className="create-comment">
      <Toast ref={toast} position="bottom-center"/>
      <div className="p-inputgroup flex-1">
        <span className="p-inputgroup-addon"></span>
        <InputMask mask="99999999999" value={phone} onChange={e => setPhone(e.value)} placeholder="Номер клиента" />
      </div>

      <div className="card flex justify-content-center">
        <InputTextarea value={comment} onChange={e => setComment(e.target.value)} placeholder="Комментарий" />
      </div>

      <Dropdown value={model} onChange={(e) => {
            setModel(e.value)
            setLoading(!loading)
        }} options={modelsInfo} optionLabel="name" 
        placeholder="Выбери модель" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>

      <Button onClick={create}>Добавить</Button>
    </div>
  );
};
