import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { ContentTop } from "../UI/ContentTop";
import { ContentFilter } from "../UI/ContentFilter";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { createManagerTablesApi, deleteManagerTablesApi, editManagerTablesApi, getAllManagerTablesApi } from "../../http/managerTables";
import { dateView, getRange, managerTableStatus } from "../../utills/functions";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode } from "primereact/api";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dialog } from "primereact/dialog";
import { NavLink } from "react-router-dom";

export const ManagerDebt = () => {
    const [loading, setLoading] = useState(true)
    const user = useSelector((state) => state.user.user);
    const [items, setItems] = useState([])
    const [value, setValue] = useState(user.role === "ADMIN" ? {name: "Все задолженности"} : {name: "Мои задолженности"})
    const [dates, setDates] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);
    const toast = useRef(null);
    
    const [filters, setFilters] = useState({
        dogovor: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [dogovorFilterValue, setDogovorFilterValue] = useState('');
    const [createDialog, setCreateDialog] = useState(false)
    const [createItem, setCreateItem] = useState("")
    const [createSum, setCreateSum] = useState('')
    const [createStatus, setCreateStatus] = useState("Выполнен")
    const [createComment, setCreateComment] = useState("")

    useEffect(() => {
        getAllManagerTablesApi(null, null, true, null, getRange(dates), value.name === "Мои задолженности" ? user.id : null)
        .then(response => {
            setItems(response.data)
        })
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [loading, setLoading, user, dates, value])

    const onDogovorFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['dogovor'].value = value;

        setFilters(_filters);
        setDogovorFilterValue(value);
    };

    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Задолженности</div>
                    <div className="content-top-app">
                        <Button onClick={create} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {items.length} шт.</div>
                <div className="content-filter-app">
                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={dogovorFilterValue} onChange={onDogovorFilterChange} placeholder="Поиск по договору" />
                    </IconField>
                    <Dropdown value={value} onChange={(e) => {
                        setValue(e.value)
                        setLoading(!loading)
                    }} options={[{name: "Мои задолженности"}, {name: "Все задолженности"}]} optionLabel="name" 
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" dateFormat="dd/mm/yy" readOnlyInput hideOnRangeSelection />
                </div>
            </ContentFilter>
            </>
        )
    }
    

    const create = () => {
        setCreateDialog(true)
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
        
        if(user.id === newData.userId || user.role === "ADMIN") {
            try {
                editManagerTablesApi(newData.id, "", newData.dogovor, newData.sum, newData.status, newData.comment, false, false)
                toast.current.show({severity:'success', summary: 'Успешно!', detail: "Задолженность изменена!", life: 3000});
                setLoading(!loading)
            } catch (error) {
                toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
            }
        } else {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: "Вам запрещено редактировать чужие звдолженности!", life: 3000});
            return
        }
    };

    const dateOrder = (i) => {
        return <div>{dateView(i.createdAt)} / {i.managerName}</div>
    }

    const sumOrder = (i) => {
        if(i.sum) return <div style={{textAlign:"center"}}>{Number(i.sum).toLocaleString("ru")} ₽</div>
    }

    const statusOrder = (i) => {
        return <div className={`manager-status-table manager-status-table-${managerTableStatus(i.status)}`}>{i.status}</div>
    }

    const dogovorView = (i) => {
        return <NavLink style={{color: "blue", textDecoration: "underline"}} target="_blank" rel="noreferrer" to={`https://rent.electrozona.ru/contracts/${i.dogovor}`}>
                    {i.dogovor}
                </NavLink>
    }

    const dogovorEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const sumEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const statusEditor = (options) => {
        const arr = [{name: "Новый"}, {name: "Выполнен"}, {name: "Отменен"}, {name: "Ожидает оплаты"}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    };

    const commentEditor = (options) => {
        return <InputTextarea style={{width: "100%"}} value={options.value} onChange={e => options.editorCallback(e.target.value)}/>
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                deleteManagerTablesApi(i.id)
                  setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить заказ?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const createItemButton = async () => {
        try {
            // let item = items.find(i => i.dogovor === createItem)
            let item = await getAllManagerTablesApi(null, null, true, null, getRange(dates), null)
            .then(response => response.data.find(e => e.dogovor === createItem))
            .catch(e => alert(e))
            
            if(item) {
                let diff = Math.round((new Date(item.createdAt) - new Date()) / (1000 * 60 * 60 * 24)) * (-1)
                if(diff <= 15) {
                    toast.current.show({severity:'error', summary: 'Ошибка', detail: `Задолженность по этому договору была добавлена менее 15 дней назад менеджером ${item.managerName} от ${new Date(item.createdAt).toLocaleDateString()}`, life: 3000});
                } else if(item.status === "Ожидает оплаты") {
                    toast.current.show({severity:'error', summary: 'Ошибка', detail: `Задолженность по этому договору ожидает оплаты, созданная менеджером ${item.managerName} от ${new Date(item.createdAt).toLocaleDateString()}`, life: 3000});
                } else if(item.status === "Новый") {
                    toast.current.show({severity:'error', summary: 'Ошибка', detail: `Задолженность по этому договору имеет статус Новый, созданная менеджером ${item.managerName} от ${new Date(item.createdAt).toLocaleDateString()}`, life: 3000});
                } else {
                    toast.current.show({severity:'success', summary: 'Успешно', detail: `Заявка успешно создана`, life: 3000});
                    createManagerTablesApi(user.name, null , createItem, createSum, createStatus, createComment, false, false, false, false, true, false, false, user.id)
                    setCreateDialog(false)
                    setLoading(!loading)
                }
            } else {
                toast.current.show({severity:'success', summary: 'Успешно', detail: `Заявка успешно создана`, life: 3000});
                createManagerTablesApi(user.name, null , createItem, createSum, createStatus, createComment, false, false, false, false, true, false, false, user.id)
                setCreateDialog(false)
                setLoading(!loading)
            }
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: error, life: 3000});
        }
    }

    return (
        <div className="field">
            <Toast ref={toast} position="bottom-center"/>
            <ConfirmDialog />
            <DataTable filters={filters} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable  paginator rows={25} rowsPerPageOptions={[25, 50, 100]} tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
                <Column header={"Дата/Менеджер"} body={dateOrder} style={{width: "18%"}}/>
                <Column editor={(options) => dogovorEditor(options)} header={"Договор"} body={dogovorView} field="dogovor" style={{width: "7%", textAlign: "center"}}/>
                <Column editor={(options) => sumEditor(options)} header={"Сумма"} body={sumOrder} field="sum" style={{width: "7%"}}/>
                <Column editor={(options) => statusEditor(options)} header={"Статус"} body={statusOrder} field="status" style={{width: "8%"}}/>
                <Column editor={(options) => commentEditor(options)} header={"Комментарий"} field="comment" style={{width: ""}}/>
                <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                {user.role === "ADMIN" && <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>}
            </DataTable>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={createDialog} onHide={() => {if (!createDialog) return; setCreateDialog(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="phone" className="font-bold">
                            Номер договора
                        </label>
                        <InputText value={createItem} onChange={e => setCreateItem(e.target.value)} id="phone"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="phone" className="font-bold">
                            Сумма
                        </label>
                        <InputText value={createSum} onChange={e => setCreateSum(e.target.value)} id="phone"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Статус
                        </label>
                        <Dropdown
                            value={createStatus}
                            options={["Новый", "Выполнен", "Отменен", "Ожидает оплаты"]}
                            onChange={(e) => setCreateStatus(e.target.value)}
                            placeholder="Select a Status"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="comment" className="font-bold">
                            Комментарий
                        </label>
                        <InputTextarea id="comment" value={createComment} onChange={e => setCreateComment(e.target.value)} />
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>
        </div>
    )
}