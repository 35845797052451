import { $host } from "./index";

export const createIdeasApi = async (item) => {
  const { data } = await $host.post("api/ideas", item);
  return data;
};

export const getAllIdeasApi = async (statusFilter) => {
  const data = await $host.get("api/ideas", {
    params: {
      status: statusFilter
    }
  });
  return data;
};

export const getOneIdeasApi = async (id) => {
  const data = await $host.get(`api/ideas/${id}`);
  return data;
};

export const deleteIdeasApi = async (id, file) => {
  const data = await $host.delete(`api/ideas/${id}`, {
    params: {file}
  });
  return data;
};

export const editIdeasApi = async (id, description, status, availability) => {
  const data = await $host.put(`api/ideas/${id}`, { description, status, availability});
  return data;
};

export const deleteIdeasFileApi = async (id) => {
  const data = await $host.delete(`api/ideas/file/${id}`);
  return data;
};

export const uploadIdeasFileApi = async (file, id) => {
  const data = await $host.post(`api/ideas/file-upload/`, file, {
      params: {id}
  });
  return data;
};
