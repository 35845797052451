import React, { useEffect, useRef, useState } from "react";
import './index.scss'
import { ContentTop } from "../UI/ContentTop";
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ContentFilter } from "../UI/ContentFilter";
import { useSelector } from "react-redux";
import { deleteCommentClientApi, editCommentClientApi, getAllCommentClientApi } from "../../http/comments";
import { Calendar } from "primereact/calendar";
import { dateView, getRange } from "../../utills/functions";
import { CreateComment } from "./create";
import { Sidebar } from "primereact/sidebar";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { modelsInfo } from "../../utills/constants";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";

export const Comments = () => {
    const [loading, setLoading] = useState(true)
    const toast = useRef(null);
    const user = useSelector((state) => state.user.user);
    const [items, setItems] = useState([])
    const [visibleRight, setVisibleRight] = useState(false);
    const [dates, setDates] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);

    useEffect(() => {
        getAllCommentClientApi(getRange(dates))
            .then(res => setItems(res.data))
            .catch(e => alert(e))
            .finally(() => setLoading(false))
    }, [loading, dates])

    const create = () => {
        setVisibleRight(!visibleRight)
    }

    const dateComment = (i) => {
        return <div>{dateView(i.createdAt)} / {i.userName}</div>
    }
    
    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Комментарии клиентов</div>
                    <div className="content-top-app">
                        <Button onClick={create} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {items.length} шт.</div>

                <div className="content-filter-app">
                <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" dateFormat="dd/mm/yy" readOnlyInput hideOnRangeSelection />
                    {user.role === "ADMIN" && (
                        <>
                        <Button icon="pi pi-file-excel" style={{background: "green", height: "26px"}}/>
                        <Button icon="pi pi-chart-line" style={{height: "26px"}}/>
                        </>
                    )}
                </div>
            </ContentFilter>
            </>
        )
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                  deleteCommentClientApi(i.id)
                  setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить комментарий?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;

        try {
            setItems(_items);
            editCommentClientApi(newData.id, newData.phoneClient, newData.model, newData.comment)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Комментарий изменен!", life: 3000});
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
    };

    const phoneEditor = (options) => {
        return <InputText style={{fontSize: "14px"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const modelEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={modelsInfo.map(e => e.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Model"
            />
        );
    };
    const commentEditor = (options) => {
        return <InputTextarea type="textarea" style={{width: "100%", fontSize: '14px'}} rows={5} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    return (
        <div className="field">
            <Toast ref={toast} position="bottom-center"/>
            <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                <CreateComment loading={loading} setLoading={setLoading} user={user} setShow={setVisibleRight}/>
            </Sidebar>
            <ConfirmDialog />

            <DataTable editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable  paginator rows={25} rowsPerPageOptions={[25, 50, 100]} tableStyle={{ minWidth: '45rem' }}>
                <Column body={dateComment} header="Дата/Сотрудник" style={{ width: '20%' }}></Column>
                <Column editor={(options) => phoneEditor(options)} field="phoneClient" header="Номер клиента" bodyStyle={{ textAlign: 'center' }} style={{ width: '10%' }}></Column>
                <Column editor={(options) => modelEditor(options)} field="model" header="Модель" bodyStyle={{ textAlign: 'center' }} style={{ width: '10%' }}></Column>
                <Column editor={(options) => commentEditor(options)} field="comment" header="Комментарий" style={{ width: '57%' }}></Column>
                <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                {user.role === "ADMIN" && <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>}
            </DataTable>
        </div>
    )
}