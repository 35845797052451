import { $host } from "./index";

export const createCompanyPaymentsApi = async (company, order, status, comment, userName, params) => {
    const { data } = await $host.post("api/company-payments", { company, order, status, comment, userName, params });
    return data;
};
  
export const getAllCompanyPaymentsApi = async (statusFilter) => {
      const data = await $host.get("api/company-payments", {
        params: {
          status: statusFilter
        }
      });
      return data;
};
  
export const statusCompanyPaymentsApi = async (id, status) => {
      const data = await $host.patch(`api/company-payments/${id}`, {status});
      return data;
};

export const deleteCompanyPaymentsApi = async (id) => {
    const data = await $host.delete(`api/company-payments/${id}`);
    return data;
}

export const editCompanyPaymentsApi = async (id, order, status, comment) => {
    const data = await $host.put(`api/company-payments/${id}`, {order, status, comment});
    return data;
  };

  // acts ----------------------------------------------------------------------------------

  export const createCompanyActsApi = async (company, order, system, status, comment, userName) => {
    const { data } = await $host.post("api/company-acts", { company, order, system, status, comment, userName });
    return data;
  };
  
  export const getAllCompanyActsApi = async (statusFilter) => {
    const data = await $host.get("api/company-acts" , {
      params: {
        status: statusFilter
      }
    });
    return data;
  };
  
  export const statusCompanyActsApi = async (id, status) => {
    const data = await $host.patch(`api/company-acts/${id}`, {status});
    return data;
  };
  
  // export const getOneCompanyActsApi = async (id) => {
  //   const data = await $host.get(`api/company-acts/${id}`);
  //   return data;
  // };
  
  export const deleteCompanyActsApi = async (id) => {
    const data = await $host.delete(`api/company-acts/${id}`);
    return data;
  };
  
  export const editCompanyActsApi = async (id, order, system, status, comment) => {
    const data = await $host.put(`api/company-acts/${id}`, {order, system, status, comment});
    return data;
  };


// payments -------------------------------------------------------------------

export const createPaymentsApi = async (payment) => {
  const { data } = await $host.post("api/payments",  payment );
  return data;
};

export const getAllPaymentsApi = async (statusFilter, articleFilter) => {
  const data = await $host.get("api/payments", {
    params: {
      status: statusFilter,
      article: articleFilter
    }
  });
  return data;
};

export const getOnePaymentsApi = async (id) => {
  const data = await $host.get(`api/payments/${id}`);
  return data;
};

export const deletePaymentsApi = async (id) => {
  const data = await $host.delete(`api/payments/${id}`);
  return data;
};

export const editPaymentsApi = async (id, companyName, companyId, fromCompany, name, status, sum, article, order) => {
  const data = await $host.put(`api/payments/${id}`, {companyName, companyId, fromCompany, name, status, sum, article, order});
  return data;
};

//-----------------------------------------------------------------------------------

export const createCompanyFilesApi = async (file) => {
  const { data } = await $host.post("api/company-files",  file );
  return data;
};

export const getAllCompanyFilesApi = async () => {
  const data = await $host.get("api/company-files");
  return data;
};

export const getOneCompanyFilesApi = async (id) => {
  const data = await $host.get(`api/company-files/${id}`);
  return data;
};

export const editCompanyFilesApi = async (id, companyId, companyName, name, type, user, conclude) => {
  const data = await $host.put(`api/company-files/${id}`, { companyId, companyName, name, type, user, conclude});
  return data;
};

export const deleteCompanyFilesApi = async (id, file) => {
  const data = await $host.delete(`api/company-files/${id}`, {
    params: {
        file
    }
  });
  return data;
};

export const deleteCompanyFileApi = async (id) => {
  const data = await $host.delete(`api/company-files/file/${id}`);
  return data;
};

export const uploadCompanyFileApi = async (file, id) => {
  const data = await $host.post(`api/company-files/file-upload/`, file, {
      params: {id}
  });
  return data;
};
