import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { ContentTop } from "../UI/ContentTop";
import { ContentFilter } from "../UI/ContentFilter";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { getAllPriceApi } from "../../http/informationApi";
import { FilterMatchMode } from "primereact/api";

export const InformationPrice = () => {
    const [items, setItems] = useState([])
    const [filters, setFilters] = useState({
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [nameFilterValue, setNameFilterValue] = useState('');

    useEffect(() => {
        getAllPriceApi()
          .then((response) => {
            setItems(response.data)
          })
          .catch((e) => console.log(e))
      }, []);

    const onNameFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['name'].value = value;

        setFilters(_filters);
        setNameFilterValue(value);
    };  

    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Прайс лист</div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-app">
                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={nameFilterValue} onChange={onNameFilterChange} placeholder="Поиск по модели" />
                    </IconField>
                </div>
            </ContentFilter>
            </>
        )
    }

    const sumOneView = (i) => {
        return i.sum_one ? Number(i.sum_one).toLocaleString("ru") + " ₽" : "Не установлено"
    }
    const sumTwoView = (i) => {
        return i.sum_two ? Number(i.sum_two).toLocaleString("ru") + " ₽" : "Не установлено"
    }
    const sumThreeView = (i) => {
        return i.sum_three ? Number(i.sum_three).toLocaleString("ru") + " ₽" : "Не установлено"
    }
    const sumFourView = (i) => {
        return i.sum_four ? Number(i.sum_four).toLocaleString("ru") + " ₽" : "Не установлено"
    }
    const sumFiveView = (i) => {
        return i.sum_five ? Number(i.sum_five).toLocaleString("ru") + " ₽" : "Не установлено"
    }

    return (
        <DataTable filters={filters} dataKey="id" value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
            <Column header="Модель" field="name"/>
            <Column body={sumOneView} header="7 дней" field="sum_one" style={{width: "12%", textAlign: "center"}}/>
            <Column body={sumTwoView} header="14 дней" field="sum_two" style={{width: "12%", textAlign: "center"}}/>
            <Column body={sumThreeView} header="30 дней" field="sum_three" style={{width: "12%", textAlign: "center"}}/>
            <Column body={sumFourView} header="60 дней" field="sum_four" style={{width: "12%", textAlign: "center"}}/>
            <Column body={sumFiveView} header="90 дней" field="sum_five" style={{width: "12%", textAlign: "center"}}/>
        </DataTable>
    )
}