import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { getAllPaymentsApi } from "../../http/w-company";
import { ContentTop } from "../UI/ContentTop";
import { ContentFilter } from "../UI/ContentFilter";
import { Calendar } from "primereact/calendar";
import { getRange } from "../../utills/functions";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import generatePDF from "react-to-pdf";

export const ReportCompany = () => {
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [dates, setDates] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);
    const [stats, setStats] = useState([])
    const [articleSum, setArticleSum] = useState([])
    const [paidSum, setPaidSum] = useState('')
    const [debtSum, setDebtSum] = useState('')
    const targetRef = useRef();
    
    useEffect(() => {
        getAllPaymentsApi("Все счета", "Все статьи")
        .then(res => setItems(res.data))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [loading, setLoading])

    useEffect(() => {
        let array = items.filter(i => {
            if(i.updatedAt > getRange(dates)[0] && i.updatedAt < getRange(dates)[1]) {
                return i
            }
        })
        let newData = array.map((item) => 
            Object.assign({}, item, item.status === "Оплачено" ? {paidSum: item.sum} : {paidSum: 0}, item.status === "Не оплачено" ? {debtSum: item.sum} : {debtSum: 0})
        )
        const res = {};
        newData.forEach(({id, companyId, sum, companyName, createdAt, updatedAt, status, name, article, fromCompany, ...dates }) => {
             const item = res[companyId] || { companyId, companyName};
             Object.keys(dates).forEach(date => {item[date] = (item[date] || 0) + Number(dates[date]);});
             res[companyId] = { ...item };
        });
        setStats(Object.values(res))
      }, [items, dates])

      useEffect(() => {
        let array = items.filter(i => {
            if(i.updatedAt > getRange(dates)[0] && i.updatedAt < getRange(dates)[1]) {
                return i
            }
        })
        let newData = array.map((item) => 
            Object.assign({}, item, item.status === "Оплачено" ? {paidSum: item.sum} : {paidSum: 0}, item.status === "Не оплачено" ? {debtSum: item.sum} : {debtSum: 0})
        )
        const res = {};
        newData.forEach(({id, companyId, sum, companyName, createdAt, updatedAt, status, name, article, fromCompany, ...dates }) => {
             const item = res[article] || { article };
             Object.keys(dates).forEach(date => {item[date] = (item[date] || 0) + Number(dates[date]);});
             res[article] = { ...item };
        });
        setArticleSum(Object.values(res))
      }, [items, dates])

      useEffect(() => {
        setPaidSum(stats.reduce((a,b) => {
            return a + b.paidSum
         }, 0))


        let newData = items.map((item) => 
            Object.assign({}, item, item.status === "Оплачено" ? {paidSum: item.sum} : {paidSum: 0}, item.status === "Не оплачено" ? {debtSum: item.sum} : {debtSum: 0})
        )
        const res = {};
        newData.forEach(({id, companyId, sum, companyName, createdAt, updatedAt, status, name, article, fromCompany, ...dates }) => {
             const item = res[companyId] || { companyId, companyName};
             Object.keys(dates).forEach(date => {item[date] = (item[date] || 0) + Number(dates[date]);});
             res[companyId] = { ...item };
        });

         setDebtSum(Object.values(res).reduce((a,b) => {
            return a + b.debtSum
         }, 0))
      }, [stats, items])

      const debtSumView = (i) => {
        return <div style={{textAlign:"center"}}>{Number(i.debtSum).toLocaleString("ru")} ₽</div>
      }

      const paidSumView = (i) => {
        return <div style={{textAlign:"center"}}>{Number(i.paidSum).toLocaleString("ru")} ₽</div>
      }

    return (
        <div className="field">
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Статистика по оплатам фирм</div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-app">
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" dateFormat="dd/mm/yy" readOnlyInput hideOnRangeSelection />
                    <Button type="button" icon="pi pi-file-pdf" severity="warning" onClick={() => generatePDF(targetRef, {filename: 'payments.pdf'})} data-pr-tooltip="PDF" />
                </div>
            </ContentFilter>

            <div ref={targetRef} className="field--tables">
                <DataTable dataKey="id" value={stats} showGridlines stripedRows selectionMode="single" scrollable  tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
                    <Column header="Фирма" field="companyName" style={{width: "50%"}}/>
                    <Column body={paidSumView} header="Оплачено" field="paidSum" style={{width: "25%"}}/>
                    <Column body={debtSumView} header="Долг" field="debtSum" style={{width: "25%"}}/>
                </DataTable>

                <div style={{display: "flex", flexDirection: "column"}}>
                    <DataTable dataKey="id" value={[{"paidSum": paidSum, "debtSum": debtSum}]} showGridlines stripedRows selectionMode="single" scrollable style={{height: "10rem"}}  tableStyle={{ minWidth: '45rem'}} emptyMessage="Пусто...">
                        <Column body={paidSumView} header="Оплачено" field="paidSum" style={{fontWeight: "700", textDecoration: "underline"}}/>
                        <Column body={debtSumView} header="Долг" field="debtSum" style={{fontWeight: "700", textDecoration: "underline"}}/>
                    </DataTable>

                    <DataTable dataKey="id" value={articleSum} showGridlines stripedRows selectionMode="single" scrollable  tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
                        <Column header="Фирма" field="article" style={{width: "50%"}}/>
                        <Column body={paidSumView} header="Оплачено" field="paidSum" style={{width: "25%"}}/>
                        <Column body={debtSumView} header="Долг" field="debtSum" style={{width: "25%"}}/>
                    </DataTable>
                </div>

            </div>

        </div>
    )
}