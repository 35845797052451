import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import user from "./reducers/user";

const rootReducer = combineReducers({
    user: user,
})

export const store = configureStore({
    reducer: rootReducer
})