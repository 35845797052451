import { $host } from "./index";

export const createSalesApi = async (sale) => {
    const { data } = await $host.post("api/sales",  sale );
    return data;
  };
  
  export const getAllSalesApi = async () => {
    const data = await $host.get("api/sales");
    return data;
  };
  
  export const getOneSalesApi = async (id) => {
    const data = await $host.get(`api/sales/${id}`);
    return data;
  };
  
  export const deleteSalesApi = async (id, file) => {
    const data = await $host.delete(`api/sales/${id}`, {
        params: {
            file
        }
    });
    return data;
  };
  
  export const editSalesApi = async (id, name, company, description, promo, sum, status, endDate) => {
    const data = await $host.put(`api/sales/${id}`, {name, company, description, promo, sum, status, endDate});
    return data;
  };

  export const deleteSalesFileApi = async (id) => {
    const data = await $host.delete(`api/sales/file/${id}`);
    return data;
  };

  export const uploadSalesFileApi = async (file, id) => {
    const data = await $host.post(`api/sales/file-upload/`, file, {
        params: {id}
    });
    return data;
  };

  //-------------PRIORITY------------------//

  export const createTaskPriority = async (name, color) => {
    const { data } = await $host.post("api/task-priority", { name, color });
    return data;
  };
  
  export const getAllTaskPriority = async () => {
    const data = await $host.get("api/task-priority");
    return data;
  };
  
  export const getOneTaskPriority = async (id) => {
    const data = await $host.get(`api/task-priority/${id}`);
    return data;
  };
  
  export const deleteTaskPriority = async (id) => {
    const data = await $host.delete(`api/task-priority/${id}`);
    return data;
  };
  
  export const editTaskPriority = async (id, name, color) => {
    const data = await $host.put(`api/task-priority/${id}`, {name, color});
    return data;
  };
  