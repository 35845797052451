import { $host } from "./index";

export const createRefundsApi = async (name, dogovor, sum, comment, status, endDate, userName) => {
  const { data } = await $host.post("api/refunds", { name, dogovor, sum, comment, status, endDate, userName });
  return data;
};

export const getAllRefundsApi = async (status, order) => {
  const data = await $host.get("api/refunds", {
    params: {
      status: status,
      order: order
    }
  });
  return data;
};

export const getSearchRefundsApi = async (dogovor) => {
  const data = await $host.get("api/refunds/search", {
    params: {
      dogovor: dogovor
    }
  });
  return data;
};


export const getOneRefundsApi = async (id) => {
  const data = await $host.get(`api/refunds/${id}`);
  return data;
};

export const deleteRefundsApi = async (id) => {
  const data = await $host.delete(`api/refunds/${id}`);
  return data;
};

export const editRefundsApi = async (id, name, dogovor, sum, comment, status, endDate) => {
  const data = await $host.put(`api/refunds/${id}`, {name, dogovor, sum, comment, status, endDate});
  return data;
};