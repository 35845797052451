import { $host } from "./index";

export const createTaskApi = async (appointId, description, priority, status, endDate, complete) => {
    const { data } = await $host.post("api/task", {appointId, description, priority, status, endDate, complete});
    return data;
};

export const getAllTaskApi = async (status) => {
    const data = await $host.get("api/task", {
        params: {
            status
        }
    });
    return data;
};

export const statusTaskApi = async (id, status) => {
    const data = await $host.patch(`api/task/${id}`, {status});
    return data;
};

export const deleteTaskApi = async (id, complete) => {
    const data = await $host.delete(`api/task/${id}`, {
      params: {
        complete
      }
    });
    return data;
  };