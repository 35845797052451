import { Tag } from "primereact/tag";
import { NavLink } from "react-router-dom";


const ir = (item, options) => (
  <NavLink key={item.id} to={item.url}>
    {item.label}
  </NavLink>
);

const irTask = (item, options) => (
  <NavLink key={item.id} to={item.url} style={{width: "100%", display: "flex"}}>
    {item.label}
    {item.badge !== 0 && <Tag value={item.badge} style={{marginLeft: "auto", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "bold", fontSize: "1.2rem", padding: ".1rem 1rem", background: "red"}}></Tag>}
  </NavLink>
);


export const linkAdmin = (value) => [
  {
    label: "Задачи",
    url: "/task",
    template: irTask,
    badge: value
  },
  {
    label: "*Ежемесячные отчеты",
    items: [
      {
        label: "Отчет по оплатам фирм",
        template: ir,
        url: "/report/company",
      },
    ]
  },
  {
    label: "Информация",
    items: [
      {
        label: "Прайс лист",
        template: ir,
        url: "/information/price",
      },
      {
        label: "Контакты",
        template: ir,
        url: "/information/contacts",
      },
      {
        label: "Акции/Скидки",
        template: ir,
        url: "/information/sales",
      },
      {
        label: "Справочник",
        template: ir,
        url: "/information/guide",
      },
    ]
  },
  {
    label: "Таблица менеджера",
    items: [
      {
        label: "Статистика",
        template: ir,
        url: "/manager-tables/statistics",
      },
      {
        label: "Заказы",
        template: ir,
        url: "/manager-tables/orders",
      },
      {
        label: "Продления",
        template: ir,
        url: "/manager-tables/prodlenie",
      },
      {
        label: "Задолженности",
        template: ir,
        url: "/manager-tables/debt",
      },
      {
        label: "Просроченные",
        template: ir,
        url: "/manager-tables/overdue",
      },
    ],
  },
  {
    label: "Работа с компаниями",
    items: [
      {
        label: "Счета на оплату",
        template: ir,
        url: "/w-company/payments",
      },
      {
        label: "Нужно выставить счёт",
        template: ir,
        url: "/w-company/need-payment",
      },
      {
        label: "Нужно сделать акт",
        template: ir,
        url: "/w-company/need-act",
      },
      {
        label: "Документы",
        template: ir,
        url: "/w-company/documents",
      },
    ],
  },
  {
    label: "Компании",
    template: ir,
    url: "/company",
  },
  {
    label: "Идеи на развитие",
    url: "/ideas",
    template: ir,
  },
  {
    label: "Комментарии клиентов",
    url: "/comments-client",
    template: ir,
  },
  {
    label: "Возвраты",
    url: "/refunds",
    template: ir,
  },
];

export const linkManager = (value) => [
  {
    label: "Задачи",
    url: "/task",
    template: irTask,
    badge: value
  },
  {
    label: "Информация",
    items: [
      {
        label: "Прайс лист",
        template: ir,
        url: "/information/price",
      },
      {
        label: "Контакты",
        template: ir,
        url: "/information/contacts",
      },
      {
        label: "Акции/Скидки",
        template: ir,
        url: "/information/sales",
      },
      {
        label: "Справочник",
        template: ir,
        url: "/information/guide",
      },
    ]
  },
  {
    label: "Таблица менеджера",
    items: [
      {
        label: "Заказы",
        template: ir,
        url: "/manager-tables/orders",
      },
      {
        label: "Продления",
        template: ir,
        url: "/manager-tables/prodlenie",
      },
      {
        label: "Задолженности",
        template: ir,
        url: "/manager-tables/debt",
      },
      {
        label: "Просроченные",
        template: ir,
        url: "/manager-tables/overdue",
      },
    ],
  },
  {
    label: "Работа с компаниями",
    items: [
      {
        label: "Счета на оплату",
        template: ir,
        url: "/w-company/payments",
      },
      {
        label: "Нужно выставить счёт",
        template: ir,
        url: "/w-company/need-payment",
      },
      {
        label: "Нужно сделать акт",
        template: ir,
        url: "/w-company/need-act",
      },
      {
        label: "Документы",
        template: ir,
        url: "/w-company/documents",
      },
    ],
  },
  {
    label: "Компании",
    template: ir,
    url: "/company",
  },
  {
    label: "Идеи на развитие",
    url: "/ideas",
    template: ir,
  },
  {
    label: "Комментарии клиентов",
    url: "/comments-client",
    template: ir,
  },
  {
    label: "Возвраты",
    url: "/refunds",
    template: ir,
  },
];
