import React, { useEffect } from "react";
import '../styles/content.scss'
import { Sidebar } from "../components/UI/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useHref, useNavigate } from "react-router-dom";
import { getAllUsers } from "../http/userApi";
import { getAllUsersState } from "../store/reducers/user";
import { AdminRoutes, ManagerRoutes } from "../utills/routes";

export const Content = () => {
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch()
    const www = useHref()
    const navigate = useNavigate()
  
    useEffect(() => {
      getAllUsers().then(response => dispatch(getAllUsersState(response.data))).catch(e => console.log(e))
  }, [dispatch])
  
    useEffect(() => {
      if(www === "/") navigate("/manager-tables")
  }, [www, navigate])

    return (
            <div className="content">
                <Sidebar/>
                {user.role === "ADMIN" ? (
                    <Routes>
                        {AdminRoutes.map((route) => {
                            return (
                            <Route key={route.id} path={route.path} element={route.element} />
                            );
                        })}
                    </Routes>
                ) : (
                    <Routes>
                        {ManagerRoutes.map((route) => {
                            return (
                            <Route key={route.id} path={route.path} element={route.element} />
                            );
                        })}
                    </Routes>
                )}
            </div>
    )
}