import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { ContentTop } from "../UI/ContentTop";
import { ContentFilter } from "../UI/ContentFilter";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { createGuidesApi, deleteGuidesApi, getAllGuidesApi } from "../../http/informationApi";
import { FilterMatchMode } from "primereact/api";
import { useSelector } from "react-redux";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";
import { Dialog } from "primereact/dialog";

export const InformationGuides = () => {
    const [loading, setLoading] = useState(true)
    const user = useSelector((state) => state.user.user);
    const [items, setItems] = useState([])
    const toast = useRef(null);
    const [visible, setVisible] = useState(false)
    const [filters, setFilters] = useState({
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [nameFilterValue, setNameFilterValue] = useState('');
    const [name, setName] = useState("")
    const [file, setFile] = useState("")

    useEffect(() => {
        getAllGuidesApi()
          .then((response) => {
            setItems(response.data)
          })
          .catch((e) => console.log(e))
          .finally(() => setLoading(false))
      }, [loading, setLoading]);

    const onNameFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['name'].value = value;

        setFilters(_filters);
        setNameFilterValue(value);
    };  

    const create = () => {
        setVisible(true)
    }

    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Справочник</div>
                    {user.role === "ADMIN" && (
                        <div className="content-top-app">
                            <Button onClick={create} label="Добавить" icon="pi pi-plus"/>
                        </div>
                    )}
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-app">
                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={nameFilterValue} onChange={onNameFilterChange} placeholder="Поиск по названию" />
                    </IconField>
                </div>
            </ContentFilter>
            </>
        )
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                deleteGuidesApi(i.id, i.file)
                toast.current.show({severity:'success', summary: 'Успешно', detail: `Заказ успешно удален`, life: 3000});
                setLoading(true)
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить справочник?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.5rem' }}></i>
    }

    const fileView = (i) => {
        if(i.file.split('.').pop() === "MOV" || i.file.split('.').pop() === "mp4") return <NavLink target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}><i className="pi pi-video" style={{fontSize: "25px", color: "black"}}></i></NavLink>
        if(i.file.split('.').pop() === "png" || i.file.split('.').pop() === "jpg") return <NavLink target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}><i className="pi pi-image" style={{fontSize: "25px", color: "black"}}></i></NavLink>
        if(i.file.split('.').pop() === "pdf") return <NavLink target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}><i className="pi pi-file-pdf" style={{fontSize: "25px", color: "black"}}></i></NavLink>
        if(i.file.split('.').pop() === "xlsx" || i.file.split('.').pop() === "xls") return <NavLink target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}><i className="pi pi-file-excel" style={{fontSize: "25px", color: "black"}}></i></NavLink>
        if(i.file.split('.').pop() === "doc" || i.file.split('.').pop() === "docx") return <NavLink target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}><i className="pi pi-file-word" style={{fontSize: "25px", color: "black"}}></i></NavLink>
        if(i.file.split('.').pop() === "txt") return <NavLink target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}><i className="pi pi-file" style={{fontSize: "25px", color: "black"}}></i></NavLink>
    }

    const createItemButton = () => {
        const formData = new FormData()
        formData.append("name", name)
        formData.append("file", file)
        try {
            createGuidesApi(formData)
            setVisible(false)
            setLoading(true)
            toast.current.show({severity:'success', summary: 'Успешно', detail: `Справочник успешно создан`, life: 3000});
        } catch (error) {
            alert(error)
        }
    }

    return (
        <>
        <Toast ref={toast} position="bottom-center"/>
        <ConfirmDialog />
        <DataTable filters={filters} dataKey="id" value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
            <Column body={fileView} header="Файл" field="file" style={{width: "7%", textAlign: "center"}}/>
            <Column header="Название" field="name"/>
            {user.role === "ADMIN" && <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>}
        </DataTable>

        <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="name" className="font-bold">
                            Название
                        </label>
                        <InputText value={name} onChange={e => setName(e.target.value)} id="name"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Файл
                        </label>
                        <input type="file" onChange={e => setFile(e.target.files[0] || null)}/>
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>
        </>
    )
}