export const sources = [
  {
    label: "Источники",
    items: [
      {
        label: "Админка аренды",
        url: "https://rent.electrozona.ru/",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        label: "arenda-minako.ru",
        url: "http://arenda-minako.ru",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        label: "minako.ru",
        url: "http://minako.ru",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        label: "Цены на запчасти",
        url: "https://docs.google.com/spreadsheets/d/1XbBId8Kg0P1KaLNkN_QpLzsO4-RwdZ-0/edit?rtpof=true#gid=706597819",
        target: "_blank",
        rel: "noreferrer",
      },
    ],
  },
];