import React, { useRef, useState } from "react";
import '../styles/auth.scss'
import { login } from "../http/userApi";
import { getAuth, getUser } from "../store/reducers/user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";

export const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [log, setLog] = useState("");
  const [password, setPassword] = useState("");
  const toast = useRef(null);

  const loginClick = async () => {
    let data;
    try {
      data = await login(log, password);
      dispatch(getUser(data));
      dispatch(getAuth(true));
      navigate("/");
    } catch (e) {
      toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
      navigate("/login");
    }
  };

  const onKeyPressLogin = async (e) => {
    let data;
    if (e.key === "Enter") {
      try {
        data = await login(log, password);
        dispatch(getUser(data));
        dispatch(getAuth(true));
        navigate("/");
      } catch (e) {
        toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        navigate("/login");
      }
    }
  };
  return (
    <div onKeyPress={onKeyPressLogin} className="login">
      <div action="" className="login-form">
        <p className="login-form-title">Личный кабинет</p>
        <div className="login-form-content">

          <div className="login-form-row">
            <FloatLabel>
                <InputText id="username" value={log} onChange={(e) => setLog(e.target.value)} />
                <label htmlFor="username">Логин</label>
            </FloatLabel>
          </div>

          <div className="login-form-row">
            <FloatLabel>
                <InputText id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <label htmlFor="password">Пароль</label>
            </FloatLabel>
          </div>

          <div className="login-form-row">
            <Button icon={"pi pi-sign-in"} label="Войти" onClick={loginClick} className="login-btn" />
          </div>

        </div>
      </div>
      <Toast ref={toast} position="top-center"/>
    </div>
  );
};
