import React, { useEffect } from "react";
import './index.scss'
import { Route,  Routes, useHref, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ManagerOrders } from "./orders";
import { ManagerProdlenie } from "./prodlenie";
import { ManagerDebt } from "./debt";
import { ManagerOverdue } from "./overdue";
import { ManagerStatistics } from "./statistics";

export const ManagerTables = () => {
    const user = useSelector((state) => state.user.user);
    const href = useHref()
    const navigate = useNavigate()

    useEffect(() => {
        if(user.role === "ADMIN" && href === "/manager-tables") navigate('/manager-tables/statistics')
        if(user.role === "MANAGER" && href === "/manager-tables") navigate('/manager-tables/orders')
    }, [href, navigate, user])

    return (
        <Routes>
            {user.role === "ADMIN" && <Route path={"statistics"} element={<ManagerStatistics/>}/>}
            <Route path={"orders"} element={<ManagerOrders/>}/>
            <Route path={"prodlenie"} element={<ManagerProdlenie/>}/>
            <Route path={"debt"} element={<ManagerDebt/>}/>
            <Route path={"overdue"} element={<ManagerOverdue/>}/>
        </Routes>
    )
}