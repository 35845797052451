import React, { useEffect, useRef, useState } from "react";
import './index.scss'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContentTop } from "../UI/ContentTop";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { ContentFilter } from "../UI/ContentFilter";
import { createRefundsApi, deleteRefundsApi, editRefundsApi, getAllRefundsApi } from "../../http/refundsApi";
import { dateView, managerTableStatus } from "../../utills/functions";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { NavLink } from "react-router-dom";
import { Dialog } from "primereact/dialog";

export const Refunds = () => {
    const [loading, setLoading] = useState(true)
    const user = useSelector((state) => state.user.user);
    const [items, setItems] = useState([])
    const toast = useRef(null);
    const [dogovor, setDogovor] = useState("");
    const [sum, setSum] = useState("");
    const [comment, setComment] = useState("");
    const [endDate, setEndDate] = useState(new Date(Date.now() + 10 * 24*3600*1000));
    const [value, setValue] = useState({name: "По умолчанию"})
    const [value2, setValue2] = useState({name: "Действующие"})
    const [visible, setVisible] = useState(false)
    const [createDialog, setCreateDialog] = useState(false)
    const [filters, setFilters] = useState({
        dogovor: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [dogovorFilterValue, setDogovorFilterValue] = useState('');

    useEffect(() => {
        let status = null
        let order = null
        if(value2.name === "Действующие") status = "Не выполнен"
        if(value2.name === "Завершенные") status = "Выполнен"
        if(value.name === "По умолчанию") order = ['createdAt','DESC']
        if(value.name === "По дате завершения") order = ['endDate', 'ASC']

        getAllRefundsApi(status, order)
            .then(res => setItems(res.data))
            .catch(e => alert(e))
            .finally(() => setLoading(false))
    }, [loading, setLoading, value, value2])

    const headerTable = () => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Возвраты</div>
                    <div className="content-top-app">
                        <Button onClick={create} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {items.length} шт.</div>
                <div className="content-filter-app">
                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={dogovorFilterValue} onChange={onDogovorFilterChange} placeholder="Поиск по договору" />
                    </IconField>
                    <Dropdown value={value2} onChange={(e) => {
                        setValue2(e.value)
                        setLoading(!loading)
                    }} options={[{name: "Все"}, {name: "Действующие"}, {name: "Завершенные"}]} optionLabel="name" 
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>
                    <Dropdown value={value} onChange={(e) => {
                        setValue(e.value)
                        setLoading(!loading)
                    }} options={[{name: "По умолчанию"}, {name: "По дате завершения"}]} optionLabel="name" 
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>
                </div>
            </ContentFilter>
            </>
        )
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
        
        try {
            editRefundsApi(newData.id, newData.name, newData.dogovor, newData.sum, newData.comment, newData.status, newData.endDate)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Возврат изменен!", life: 3000});
            setLoading(!loading)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
        
    };

    const onDogovorFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['dogovor'].value = value;

        setFilters(_filters);
        setDogovorFilterValue(value);
    };

    const create = () => {
        setVisible(true)
    }

    const dateTable = (i) => {
        return <div>{dateView(i.createdAt)} / {i.userName}</div>
    }

    const dogovorView = (i) => {
        return <div style={{display: "flex", flexDirection: "column", gap: "0.3rem"}}>
            <NavLink style={{fontSize: "1.6rem", fontWeight: "bold", textAlign: "center", color: "blue", textDecoration: "underline"}} target="_blank" rel="noreferrer" to={`https://rent.electrozona.ru/contracts/${i.dogovor}`}>
                    {i.dogovor}
            </NavLink>
        </div>
    }

    const sumView = (i) => {
        if(i.sum) return <div style={{fontWeight: "bold", color: "red", textDecoration: "underline"}}>{Number(i.sum).toLocaleString("ru")} ₽</div>
    }

    const endDateView = (i) => {
        let today = new Date();
        let countDays = Math.floor((new Date(i.endDate) - today) / (1000 * 60 * 60 * 24)) + 1;
        const styleDaysToReturn = () => {
            if (i.status === "Не выполнен") {
              if (countDays > 3) return "green";
              if (countDays >= 0 && countDays <= 3) return "orange";
              if (countDays < 0) return "red";
            }
        };
        return (
            <div style={{display: "flex", flexDirection: "column", gap: "0.3rem"}}>
                {i.status === "Не выполнен" && <div>
                    <span>Вернуть до: </span>
                    <span style={{textDecoration: "underline", fontWeight: "bold"}}>
                        {new Date(i.endDate).toLocaleDateString("ru", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",})}
                    </span> 
                </div>}
                {i.status !== "Не выполнен" && <div className={`manager-status-table manager-status-table-${managerTableStatus(i.status)}`}>{i.status}</div>}
                {i.status === "Не выполнен" && <div className="count-status">
                    <span>Осталось: </span>
                    <span className={`rcard-footer-date-days-count rcard-footer-date-days-count-${styleDaysToReturn()}`}>{countDays} д.</span> 
                </div>}
                {i.status !== "Не выполнен" && <div>
                    <span>{i.status}: </span>
                    <span style={{textDecoration: "underline", fontWeight: "bold"}}>
                        {new Date(i.updatedAt).toLocaleDateString("ru", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",})}
                    </span> 
                </div>}
            </div>
        )
    }

    const refundDone = (item) => {
        try {
          editRefundsApi( item.id, item.name, item.dogovor, item.sum, item.comment, "Выполнен", item.endDate);
        } catch (error) {
          alert(error);
        }
        setLoading(!loading);
    };
  
    const refundRestore = (item) => {
          try {
            editRefundsApi( item.id, item.name, item.dogovor, item.sum, item.comment, "Не выполнен", item.endDate);
          } catch (error) {
            alert(error);
          }
          setLoading(!loading);
    };

    const refundCancel = (item) => {
        try {
          editRefundsApi( item.id, item.name, item.dogovor, item.sum, item.comment, "Отменен", item.endDate );
        } catch (error) {
          alert(error);
        }
        setLoading(!loading);
    };

    const buttonStatusRefund = (i) => {
        return (
            <div className="rcard-header-date-buttons">
                {(i.status === "Не выполнен" && user.role === "ADMIN") && <Button onClick={() => refundDone(i)} icon="pi pi-check" severity="success"/>}
                {(i.status === "Не выполнен" && user.role === "ADMIN") && <Button onClick={() => refundCancel(i)} icon="pi pi-times" severity="danger"/>}
                {(i.status !== "Не выполнен") && <Button onClick={() => refundRestore(i)} icon="pi pi-sync" severity="help"/>}
            </div>
        )
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                  deleteRefundsApi(i.id)
                  setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить возврат?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const dogovorEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const sumEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const commentEditor = (options) => {
        return <InputTextarea style={{width: "100%"}} value={options.value} onChange={e => options.editorCallback(e.target.value)}/>
    }

    const endDateEditor = (options) => {
        return <Calendar dateFormat="dd/mm/yy" style={{width: "100%"}} value={new Date(options.value)} onChange={e => options.editorCallback(e.target.value)}/>
    }

    const createItemButton = () => {
        if(!dogovor || !sum || !comment || !endDate) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: "Заполни все поля", life: 3000});
            return
        }
        
        let repeat = items.find(i => i.dogovor === dogovor)
        if(repeat) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: "Возврат на такой договор уже существует", life: 3000});
            return
        }
    
          setLoading(!loading)
          try {
              createRefundsApi("Возврат по договору", dogovor, sum, comment, "Не выполнен", new Date(endDate).toISOString(), user.name)
              setLoading(!loading)
              setCreateDialog(!createDialog)
              setDogovor('')
              setSum('')
              setComment('')
              setEndDate(new Date(Date.now() + 10 * 24*3600*1000))
          } catch (error) {
              alert(error)
          }
    }

    return (
        <div className="field">
            <Toast ref={toast} position="bottom-center"/>
            <ConfirmDialog />
            <DataTable filters={filters} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable  paginator rows={25} rowsPerPageOptions={[25, 50, 100]} tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
                <Column body={dateTable} header="Дата/Сотрудник" field="createdAt" style={{width: "18%"}}/>
                <Column editor={dogovorEditor} body={dogovorView} header="Договор" field="dogovor" style={{width: "6%"}}/>
                <Column editor={sumEditor} body={sumView} header="Сумма" field="sum" style={{width: "7%", textAlign: "center"}}/>
                <Column editor={commentEditor} header="Причина" field="comment"/>
                <Column editor={endDateEditor} body={endDateView} header="Срок/Статус" field="endDate" style={{width: "15%"}}/>
                <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>
                {user.role === "ADMIN" && <Column body={buttonStatusRefund} style={{width: "5%"}}/>}
            </DataTable>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={createDialog} onHide={() => {if (!createDialog) return; setCreateDialog(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="phone" className="font-bold">
                            Номер договора
                        </label>
                        <InputText value={dogovor} onChange={e => setDogovor(e.target.value)} id="phone"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="sum" className="font-bold">
                            Сумма
                        </label>
                        <InputText value={sum} onChange={e => setSum(e.target.value)} id="sum"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="comment" className="font-bold">
                            Комментарий
                        </label>
                        <InputTextarea id="comment" value={comment} onChange={e => setComment(e.target.value)} />
                    </div>

                    <div className="create-row">
                        <label htmlFor="comment" className="font-bold">
                            Срок до
                        </label>
                            <Calendar
                                id="buttondisplay"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                dateFormat="dd MM yy"
                            />
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>
            <ConfirmDialog
                group="declarative"
                visible={visible}
                onHide={() => setVisible(false)}
                message="Ты позвонил клиенту?"
                header="Предупреждение"
                icon="pi pi-exclamation-triangle"
                accept={() => setCreateDialog(true)}
                reject={() => toast.current.show({ severity: 'warn', summary: 'Ошибка', detail: 'Сначала нужно позвонить клиенту', life: 3000 })}
                style={{ width: '50vw' }}
                breakpoints={{ '1100px': '75vw', '960px': '100vw' }}
                acceptLabel="Да."
                rejectLabel="Нет. :("
            />
        </div>
    )
}