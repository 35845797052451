import React, { useEffect, useRef, useState } from "react";
import './index.scss'
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContentTop } from "../UI/ContentTop";
import { Button } from "primereact/button";
import { ContentFilter } from "../UI/ContentFilter";
import { createIdeasApi, deleteIdeasApi, deleteIdeasFileApi, editIdeasApi, getAllIdeasApi, uploadIdeasFileApi } from "../../http/ideasApi";
import { dateView } from "../../utills/functions";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { ideasStatus } from "../../utills/constants";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";

export const Ideas = () => {
    const [loading, setLoading] = useState(true)
    const toast = useRef(null);
    const user = useSelector((state) => state.user.user);
    const [items, setItems] = useState([])
    const [visibleRight, setVisibleRight] = useState(false);
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState({name: "Новый"});
    const [availability, setAvailability] = useState({name: "Всем"})
    const [file, setFile] = useState(null)
    const [statusFilter, setStatusFilter] = useState("Все идеи")

    useEffect(() => {
        if(loading) getAllIdeasApi(statusFilter).then(res => {
            setItems(res.data)
        }).catch(e => alert(e)).finally(() => setLoading(false))
    }, [loading, setLoading, statusFilter])

    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Идеи на развитие</div>
                    <div className="content-top-app">
                        <Button onClick={() => setVisibleRight(true)} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {user.role === "ADMIN" ? items.length : items.filter(i => i.availability !== "Руководителю").length} шт.</div>

                <div className="content-filter-app">
                    <Dropdown value={statusFilter} onChange={(e) => {
                        setStatusFilter(e.value)
                        setLoading(!loading)
                    }} options={[{name: "Все идеи"}, {name: "Новый"}, {name: "На согласовании"}, {name: "Одобрено"}, {name: "Выполнено"}, {name: "В работе"}, {name: "Не одобрено"}, {name: "Не выполнено"}].map(u => u.name)} optionLabel="name" 
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>
                </div>
            </ContentFilter>
            </>
        )
    }

    const dateIdeas = (i) => {
        return <div>{dateView(i.createdAt)} / {i.userName}</div>
    }

    const statusIdea = (i) => {
        return <span className={`status-idea status-idea-${getSeverity(i.status)}`} >{i.status}</span>
    }

    const getSeverity = (status) => {
        switch (status) {
            case 'Новый':
                return 'new';

            case 'На согласовании':
                return 'sogl';

            case 'Одобрено':
                return 'odobr';

            case 'Выполнено':
                return 'done';

            case 'В работе':
                return 'work';

            case 'Не одобрено':
                return 'noodogr';

            case 'Не выполнено':
                return 'notdone';  

            default:    
        }
    };

    const levelIdeas = (i) => {
        if(i.availability === "Всем") return <i className="pi pi-lock-open" style={{ fontSize: '1.4rem' }}></i>
        if(i.availability === "Руководителю") return <i className="pi pi-lock" style={{ fontSize: '1.4rem' }}></i>
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                  deleteIdeasApi(i.id, i.file)
                  setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить идею?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;

        try {
            setItems(_items);
            editIdeasApi(newData.id, newData.description, newData.status, newData.availability)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Комментарий изменен!", life: 3000});
            setLoading(true)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
    };

    const descriptionEditor = (options) => {
        return <InputTextarea type="textarea" style={{width: "100%", maxWidth: '100%', minWidth: "100%", fontSize: '14px'}} rows={5} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const statusEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={ideasStatus.map(e => e.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    };

    const levelEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={["Всем", "Руководителю"]}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    };

    const createItemButton = () => {
        if(!description || !status) {
            return toast.current.show({severity:'error', summary: 'Ошибка', detail: "Нужно заполнить все поля", life: 3000});
        } else {
            try {
                const formData = new FormData()
                formData.append('userName', user.name)
                formData.append('description', description)
                formData.append('status', status)
                formData.append('availability', availability)
                formData.append('file', file)
            
                createIdeasApi(formData)
                toast.current.show({severity:'success', summary: 'Успешно!', detail: "Идея добавлена!", life: 3000});

                setVisibleRight(false)
                setLoading(!loading)
            } catch (error) {
                alert(error)
            }
        }

        setDescription("")
        setStatus("")
        setAvailability("")
    }

    const pictureView = (i) => {
        if(i.file) return <Image src={`${process.env.REACT_APP_API_URL}files/${i.file}`} zoomSrc={`${process.env.REACT_APP_API_URL}files/${i.file}`} alt="Image" width="40" height="40" preview />
    } 

    const pictureEditor = (options) => {
        if(options.value) {
            return <Button onClick={() => deleteIdeasFileApi(options.rowData.id)}>Удалить</Button>
        } else {
            return <div style={{display: "flex", alignItems: "center"}}>
            <input type="file" onChange={e => setFile(e.target.files[0] || null)}/>
           {file && <Button onClick={() => {
                const formData = new FormData()
                formData.append('file', file)
                uploadIdeasFileApi(formData, options.rowData.id)
                setFile(null)
            }} icon="pi pi-check"/>}
                </div>
        }
    }
    
    return (
        <div className="field">
            <Toast ref={toast} position="bottom-center"/>
            <ConfirmDialog />

            <DataTable editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete}  value={user.role === "ADMIN" ? items : items.filter(i => i.availability !== "Руководителю")} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable  paginator rows={25} rowsPerPageOptions={[25, 50, 100]} tableStyle={{ minWidth: '45rem' }}>
                <Column body={dateIdeas} header="Дата/Сотрудник" style={{ width: '20%' }}></Column>
                <Column editor={(options) => descriptionEditor(options)}  field="description" header="Описание" style={{ width: '57%' }}></Column>
                <Column editor={(options) => statusEditor(options)} field="status"  body={statusIdea} header="Статус" bodyStyle={{ textAlign: 'center'}} style={{ width: '13%' }}></Column>
                <Column editor={pictureEditor} body={pictureView}  field="file" header="Промо" style={{ width: '5%', textAlign: "center" }}></Column>
                {user.role === "ADMIN" && <Column editor={(options) => levelEditor(options)} field="availability" body={levelIdeas} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '2%' }}></Column>}
                <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                {user.role === "ADMIN" && <Column header="" body={deleteTable} bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>}
            </DataTable>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visibleRight} onHide={() => {if (!visibleRight) return; setVisibleRight(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="description" className="font-bold">
                            Описание
                        </label>
                        <InputTextarea value={description} onChange={e => setDescription(e.target.value)} id="description"></InputTextarea>
                    </div>

                    <div className="create-row">
                        <label htmlFor="status" className="font-bold">
                            Статус
                        </label>
                        <Dropdown
                            value={status}
                            options={ideasStatus.map(i => i.name)}
                            onChange={(e) => setStatus(e.target.value)}
                            placeholder="Select a Status"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="status" className="font-bold">
                            Доступность
                        </label>
                        <Dropdown
                            value={availability}
                            options={[{name: "Всем"}, {name: "Руководителю"}].map(i => i.name)}
                            onChange={(e) => setAvailability(e.target.value)}
                            placeholder="Select an Availability"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Файл
                        </label>
                        <input type="file" onChange={e => setFile(e.target.files[0] || null)}/>
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>
        </div>
    )
}