import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { createSalesApi, deleteSalesApi, deleteSalesFileApi, editSalesApi, getAllSalesApi, uploadSalesFileApi } from "../../http/settings";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Image } from "primereact/image";

export const SettingsSales = () => {
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [visible, setVisible] = useState(false)
    const [name, setName] = useState("");
    const [company, setCompany] = useState('')
    const [description, setDescription] = useState('')
    const [promo, setPromo] = useState('')
    const [sum, setSum] = useState('')
    const [status, setStatus] = useState("Работает");
    const [endDate, setEndDate] = useState('')
    const [file, setFile] = useState(null)
    const toast = useRef(null);

    useEffect(() => {
        getAllSalesApi()
        .then((response) => {
          setItems(response.data);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }, [loading])

    const createSale = () => {
        setVisible(!visible)
    }

    const createItemButton = () => {
        try {
            const formData = new FormData()
            formData.append('name', name)
            formData.append('company', company)
            formData.append('description', description)
            formData.append('promo', promo)
            formData.append('sum', sum)
            formData.append('status', status)
            formData.append('endDate', new Date(endDate).toISOString())
            formData.append('file', file)
            
            createSalesApi(formData)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Акция создан!", life: 3000});
          } catch (e) {
            alert(e);
          }

          setVisible(!visible)
          setLoading(!loading);
    }

    const headerTable = (i) => {
        return (
            <div style={{padding: "1rem"}}>
                <Button onClick={createSale} style={{background: "#4b4b4b", border: "none", fontSize: "1.4rem"}}>Добавить</Button>
            </div>
        )
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                deleteSalesApi(i.id, i.file)
                setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить акцию?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
       
        try {
            editSalesApi(newData.id, newData.name, newData.company, newData.description, newData.promo, newData.sum, newData.status, newData.endDate)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Акция изменена!", life: 3000});
            setLoading(!loading)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
    };

    const nameEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const companyEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const promoEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const sumEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const endDateEditor = (options) => {
        return <Calendar dateFormat="dd/mm/yy" style={{width: "100%"}} value={new Date(options.value)} onChange={e => options.editorCallback(e.target.value)}/>
    }

    const commentEditor = (options) => {
        return <InputTextarea style={{width: "100%"}} value={options.value} onChange={e => options.editorCallback(e.target.value)}/>
    }

    const statusEditor = (options) => {
        const arr = [{name: "Действующая"}, {name: "Завершенная"}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    }

    const endDateView = (i) => {
        return <div>{new Date(i.endDate).toLocaleDateString("ru", {
            day: "numeric",
            month: "short",
            year: "numeric",})}</div>
    }

    const pictureView = (i) => {
        if(i.file) return <Image src={`${process.env.REACT_APP_API_URL}files/${i.file}`} zoomSrc={`${process.env.REACT_APP_API_URL}files/${i.file}`} alt="Image" width="80" height="60" preview />
    } 

    const pictureEditor = (options) => {
        if(options.value) {
            return <Button onClick={() => deleteSalesFileApi(options.rowData.id)}>Удалить</Button>
        } else {
            return <div style={{display: "flex", alignItems: "center"}}>
            <input type="file" onChange={e => setFile(e.target.files[0] || null)}/>
            {file && <Button onClick={() => {
                const formData = new FormData()
                formData.append('file', file)
                uploadSalesFileApi(formData, options.rowData.id)
                setFile(null)
            }} icon="pi pi-check"/>}
                </div>
        }
    }

    return (
        <>
        <Toast ref={toast} position="bottom-center"/>
        <ConfirmDialog />
        <DataTable editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
            <Column editor={nameEditor} header="Название" field="name"/>
            <Column editor={companyEditor} header="Компания" field="company" style={{width: "8%", textAlign: "center", fontWeight: "bold"}}/>   
            <Column editor={commentEditor} header="Описание" field="description"/> 
            <Column editor={promoEditor} header="Промокод" field="promo" style={{width: "8%", textAlign: "center", fontWeight: "bold"}}/>
            <Column editor={sumEditor} header="Скидка" field="sum" style={{width: "5%", textAlign: "center", fontWeight: "bold", textDecoration: "underline"}}/>
            <Column editor={statusEditor} header="Статус" field="status" style={{width: "8%", textAlign: "center", fontWeight: "bold"}}/>
            <Column editor={endDateEditor} body={endDateView} header="Дата окончания" field="endDate" style={{width: "8%", textAlign: "center", fontWeight: "bold"}}/>
            <Column editor={pictureEditor} body={pictureView} header="Промо" field="file" style={{width: "5%"}}/>
            <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem'}} bodyStyle={{ textAlign: 'center' }}></Column>
            <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>
        </DataTable>

        <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="name" className="font-bold">
                            Название
                        </label>
                        <InputText value={name} onChange={e => setName(e.target.value)} id="name"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="company" className="font-bold">
                            Компания
                        </label>
                        <InputText value={company} onChange={e => setCompany(e.target.value)} id="company"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="description" className="font-bold">
                            Описание
                        </label>
                        <InputTextarea value={description} onChange={e => setDescription(e.target.value)} id="description"></InputTextarea>
                    </div>

                    <div className="create-row">
                        <label htmlFor="promo" className="font-bold">
                            Промокод
                        </label>
                        <InputText value={promo} onChange={e => setPromo(e.target.value)} id="promo"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="sum" className="font-bold">
                            Сумма
                        </label>
                        <InputText value={sum} onChange={e => setSum(e.target.value)} id="sum"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="status" className="font-bold">
                            Статус
                        </label>
                        <Dropdown
                            value={status}
                            options={[{name: "Действующая"}, {name: "Завершенная"}].map(i => i.name)}
                            onChange={(e) => setStatus(e.target.value)}
                            placeholder="Select a Status"
                            style={{width: "100%"}}
                        />
                    </div>
                    
                    <div className="create-row">
                        <label htmlFor="comment" className="font-bold">
                            Срок до
                        </label>
                            <Calendar
                                id="buttondisplay"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                dateFormat="dd MM yy"
                            />
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Файл
                        </label>
                        <input type="file" onChange={e => setFile(e.target.files[0] || null)}/>
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>
        </>
    )
}