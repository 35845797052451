import React from "react";
import './index.scss'
import { Route, Routes } from "react-router-dom";
import { CompanyCards } from "./cards";
import { CompanyItem } from "./item";

export const Company = () => {
    return (
        <Routes>
            <Route path="" element={<CompanyCards/>}/>
            <Route path=":id" element={<CompanyItem/>}/>
        </Routes>
    )
}