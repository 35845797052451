import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { createTaskPriority,  deleteTaskPriority, editTaskPriority, getAllTaskPriority} from "../../http/settings";
import { ColorPicker } from "primereact/colorpicker";


export const SettingsPriority = () => {
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [visible, setVisible] = useState(false)
    const [name, setName] = useState("");
    const [color, setColor] = useState('ff0000')
    const toast = useRef(null);

    useEffect(() => {
        getAllTaskPriority()
        .then((response) => {
          setItems(response.data);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }, [loading])

    const createPriority = () => {
        setVisible(!visible)
    }

    const createItemButton = () => {
        try {
            createTaskPriority(name, color)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Приоритет создан!", life: 3000});
          } catch (e) {
            alert(e);
          }

          setVisible(!visible)
          setLoading(!loading);
    }

    const headerTable = (i) => {
        return (
            <div style={{padding: "1rem"}}>
                <Button onClick={createPriority} style={{background: "#4b4b4b", border: "none", fontSize: "1.4rem"}}>Добавить</Button>
            </div>
        )
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                deleteTaskPriority(i.id)
                setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить приоритет?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
       
        try {
            editTaskPriority(newData.id, newData.name, newData.color)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Приоритет изменен!", life: 3000});
            setLoading(!loading)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
    };

    const nameEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const colorEditor = (options) => {
        return <ColorPicker inputId="cp-hex" format="hex" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} className="mb-3" />
    }

    const colorView = (i) => {
        return <div style={{backgroundColor: `#${i.color}`,padding: ".2rem", borderRadius: ".6rem"}}>{i.color}</div>
    }

    return (
        <>
        <Toast ref={toast} position="bottom-center"/>
        <ConfirmDialog />
        <DataTable editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
            <Column editor={nameEditor} header="Название" field="name"/>
            <Column editor={colorEditor} body={colorView} header="Цвет" field="color" style={{width: "8%", textAlign: "center", fontWeight: "bold"}}/>   
            <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem'}} bodyStyle={{ textAlign: 'center' }}></Column>
            <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>
        </DataTable>

        <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="name" className="font-bold">
                            Название
                        </label>
                        <InputText value={name} onChange={e => setName(e.target.value)} id="name"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="company" className="font-bold">
                            Цвет
                        </label>
                        <ColorPicker inputId="cp-hex" format="hex" value={color} onChange={(e) => setColor(e.value)} className="mb-3" />
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>
        </>
    )
}