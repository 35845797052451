export const modelsInfo = [
    {name: "Без модели"},
    {name: "Minako V8"},
    {name: "Minako Ref"},
    {name: "Minako Monster"},
    {name: "Minako V8 ECO"},
    {name: "Minako Titan 30"},
    {name: "Minako Titan 40"},
    {name: "Minako F10/F11"},
    {name: "АКБ 60/12"},
    {name: "АКБ 60/15"},
    {name: "АКБ 60/20"},
    {name: "АКБ 60/30"},
    {name: "АКБ 60/40"},
]

export const ideasStatus = [
    {name: 'Новый'},
    {name: 'На согласовании'},
    {name: 'Одобрено'},
    {name: 'Выполнено'},
    {name: 'В работе'},
    {name: 'Не одобрено'},
    {name: 'Не выполнено'}
]