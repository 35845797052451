import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ContentTop } from "../UI/ContentTop";
import { ContentFilter } from "../UI/ContentFilter";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { createCompanyActsApi, deleteCompanyActsApi, editCompanyActsApi, getAllCompanyActsApi } from "../../http/w-company";
import { getAllCompanyApi } from "../../http/companyApi";
import { dateView, managerTableStatus } from "../../utills/functions";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { FilterMatchMode } from "primereact/api";

export const CompanyNeedAtc = () => {
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState(false)
    const user = useSelector((state) => state.user.user);
    const [items, setItems] = useState([])
    const [companies, setCompanies] = useState([])
    const toast = useRef(null);
    const [companyName, setCompanyName] = useState("")
    const [system, setSystem] = useState("")
    const [order, setOrder] = useState("")
    const [status, setStatus] = useState("Не выполнено")
    const [comment, setComment] = useState("")
    const [statusFilter, setStatusFilter] = useState("Все заявки")
    const [filters, setFilters] = useState({
        company: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [companyFilterValue, setCompanyFilterValue] = useState('');

    useEffect(() => {
        getAllCompanyActsApi(statusFilter)
        .then(res => {
          setItems(res.data)
        }).catch(e => alert(e)).finally(() => setLoading(false))
  
        getAllCompanyApi()
        .then((res) => setCompanies(res.data))
        .catch((e) => alert(e))
        .finally(() => setLoading(false));
    }, [loading, statusFilter])

    const onCompanyFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['company'].value = value;

        setFilters(_filters);
        setCompanyFilterValue(value);
    };

    const create = () => {
        setVisible(true)
    }

    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Нужно сделать акт</div>
                    <div className="content-top-app">
                        <Button onClick={create} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {items.length} шт.</div>
                <div className="content-filter-app">
                    <Dropdown value={statusFilter} onChange={(e) => {
                        setStatusFilter(e.value)
                        setLoading(!loading)
                    }} options={[{name: "Все заявки"}, {name: "Выполнено"}, {name: "Не выполнено"}, {name: "Отменено"}].map(u => u.name)} optionLabel="name" 
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>


                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={companyFilterValue} onChange={onCompanyFilterChange} placeholder="Поиск по фирме" />
                    </IconField>
                </div>
            </ContentFilter>
            </>
        )
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
        
        try {
            editCompanyActsApi(newData.id, newData.order, newData.system, newData.status, newData.comment)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Заявка изменена!", life: 3000});
            setLoading(!loading)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
      
    };

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                    deleteCompanyActsApi(i.id)
                  setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить заказ?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    const dateOrder = (i) => {
        return <div>{dateView(i.createdAt)} / {i.userName}</div>
    }

    const statusView = (i) => {
        return <div className={`payment-table payment-table-${managerTableStatus(i.status)}`}>{i.status}</div>
    }

    const statusEditor = (options) => {
        const arr = [{name: "Не выполнено"}, {name: "Выполнено"}, {name: "Отменено"}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    }

    const systemEditor = (options) => {
        const arr = [{name: "СБИС"}, {name: "Оригинал"}, {name: "Скан"}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    }

    const orderEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const commentEditor = (options) => {
        return <InputTextarea style={{width: "100%"}} value={options.value} onChange={e => options.editorCallback(e.target.value)}/>
    }

    const createItemButton = () => {
        if(!companyName) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: "Нужно выбрать фирму", life: 3000});
            return 
        }

        try {
            if(visible) createCompanyActsApi(companyName, order, system, status, comment, user.name)
        } catch (error) {
            alert(error)            
        }

        setCompanyName("")
        setOrder("")
        setStatus("")
        setSystem("")
        setComment("")

        setVisible(false)
        setLoading(!loading)
    }

    return (
        <div className="field">
            <Toast ref={toast} position="bottom-center"/>
            <ConfirmDialog />
            <DataTable filters={filters} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable  paginator rows={25} rowsPerPageOptions={[25, 50, 100]} tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
                <Column body={dateOrder} header="Дата" field="createdAt" style={{width: "18%"}}/>
                <Column header="Фирма" field="company" style={{width: "15%"}}/>
                <Column editor={orderEditor} header="Заказ" field="order" style={{width: "5%"}}/>
                <Column editor={systemEditor} header="Подпись" field="system" style={{width: "5%"}}/>
                <Column editor={statusEditor} body={statusView} header="Статус" field="status" style={{width: "10%"}}/>
                <Column editor={commentEditor} header="Комментарий" field="comment" style={{width: ""}}/>
                <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                {user.role === "ADMIN" && <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>}
            </DataTable>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Компания
                        </label>
                        <Dropdown
                            value={companyName}
                            options={companies.map(i => i.contragent)}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Select a Company"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="position" className="font-bold">
                            Заказ
                        </label>
                        <InputText value={order} onChange={e => setOrder(e.target.value)} id="position"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Подпись
                        </label>
                        <Dropdown
                            value={system}
                            options={["СБИС", "Оригинал", "Скан"]}
                            onChange={(e) => setSystem(e.target.value)}
                            placeholder="Select a Company"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="comment" className="font-bold">
                            Комментарий
                        </label>
                        <InputTextarea id="comment" value={comment} onChange={e => setComment(e.target.value)} />
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>
        </div>
    )
}