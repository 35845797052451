import React from "react";
import "./index.scss";
import { TabPanel, TabView } from "primereact/tabview";
import { ContentTop } from "../UI/ContentTop";
import { SettingsAccaunts } from "./accaunts";
import { SettingsSales } from "./sales";
import { SettingsPriority } from "./priority";

export const Settings = () => {
  return (
    <div className="field">
      <ContentTop>
        <div className="content-top-header">
          <div className="content-top-title">Настройки</div>
        </div>
      </ContentTop>
        <TabView>
          <TabPanel header="Аккаунты"><SettingsAccaunts/></TabPanel>
          <TabPanel header="Города">"В разработке..."</TabPanel>
          <TabPanel header="Прайс">"В разработке..."</TabPanel>
          <TabPanel header="Акции"><SettingsSales/></TabPanel>
          <TabPanel header="Приоритет"><SettingsPriority/></TabPanel>
        </TabView>
    </div>
  );
};
