import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ContentTop } from "../UI/ContentTop";
import { ContentFilter } from "../UI/ContentFilter";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { createCompanyFilesApi, deleteCompanyFileApi, deleteCompanyFilesApi, editCompanyFilesApi, getAllCompanyFilesApi, uploadCompanyFileApi} from "../../http/w-company";
import { getAllCompanyApi } from "../../http/companyApi";
import { dateView } from "../../utills/functions";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { FilterMatchMode } from "primereact/api";
import { NavLink } from "react-router-dom";

export const CompanyDocuments = () => {
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState(false)
    const user = useSelector((state) => state.user.user);
    const [items, setItems] = useState([])
    const [companies, setCompanies] = useState([])
    const toast = useRef(null);
    const [companyName, setCompanyName] = useState("")
    const [companyId, setCompanyId] = useState(null)
    const [name, setName] = useState("")
    const [conclude, setConclude] = useState("СБИС")
    const [type, setType] = useState("Акт")
    const [file, setFile] = useState(null)
    const [filters, setFilters] = useState({
        companyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [companyFilterValue, setCompanyFilterValue] = useState('');

    useEffect(() => {
        getAllCompanyFilesApi().then(response => {
            setItems(response.data)
        }).catch(e => alert(e)).finally(() => setLoading(false))
  
        getAllCompanyApi()
        .then((res) => setCompanies(res.data))
        .catch((e) => alert(e))
        .finally(() => setLoading(false));
    }, [loading])

    useEffect(() => {
        if(companyName) {
            let item = companies.find(f => f.contragent === companyName)
            setCompanyId(item.id)
        } else {
            setCompanyId(null)
        }
    }, [companyName, companies, companyId])

    const onCompanyFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        
        _filters['companyName'].value = value;

        setFilters(_filters);
        setCompanyFilterValue(value);
    };

    const create = () => {
        setVisible(true)
    }

    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Документы</div>
                    <div className="content-top-app">
                        <Button onClick={create} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {items.length} шт.</div>
                <div className="content-filter-app">
                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={companyFilterValue} onChange={onCompanyFilterChange} placeholder="Поиск по фирме" />
                    </IconField>
                </div>
            </ContentFilter>
            </>
        )
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;

        try {
            editCompanyFilesApi(newData.id, newData.companyId, newData.companyName, newData.name, newData.type, newData.user, newData.conclude)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Документ изменен!", life: 3000});
            setLoading(!loading)
        } catch (error) {
           toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
        
    };

    const dateOrder = (i) => {
        return <div>{dateView(i.createdAt)} / {i.userName}</div>
    }

    const fileView = (i) => {
       if(i.file) return (
            <NavLink style={{color: "blue", textDecoration: "underline"}} target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}>
                <i className="pi pi-file" style={{fontSize: "1.8rem", color: "black"}}></i>
            </NavLink>
        )
    }

    const commentEditor = (options) => {
        return <InputTextarea style={{width: "100%"}} value={options.value} onChange={e => options.editorCallback(e.target.value)}/>
    }

    const concludeEditor = (options) => {
        const arr = [{name: "СБИС"}, {name: "Оригинал"}, {name: "Скан"}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    }

    const typeEditor = (options) => {
        const arr = [{name: "Акт"}, {name: "Договор"}, {name: "Карточка"}, {name: "Шаблон"}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    }

    const fileEditor = (options) => {
        if(options.value) {
            return <Button onClick={() => deleteCompanyFileApi(options.rowData.id)}>Удалить</Button>
        } else {
            return <div style={{display: "flex", alignItems: "center"}}>
            <input type="file" onChange={e => setFile(e.target.files[0] || null)}/>
            {file && <Button onClick={() => {
                const formData = new FormData()
                formData.append('file', file)
                uploadCompanyFileApi(formData, options.rowData.id)
                setFile(null)
            }} icon="pi pi-check"/>}
                </div>
        }
    }

    const createItemButton = () => {
        if(!companyId || !companyName || !file) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: "Выбери компанию и файл", life: 3000});
            return
        } else {
            try {
                const formData = new FormData()
                formData.append("companyName", companyName)
                formData.append("companyId", companyId)
                formData.append("name", name)
                formData.append("userName", user.name)
                formData.append("conclude", conclude)
                formData.append("type", type)
                formData.append("file", file)
                
                createCompanyFilesApi(formData)
                toast.current.show({severity:'success', summary: 'Успешно!', detail: "Документ добавлен!", life: 3000});
              } catch (error) {
                  alert(error)            
              }
        }
  
          setCompanyName("")
          setCompanyId(null)
          setName("")
          setConclude("СБИС")
          setType("Акт")
          setFile(null)
          setLoading(!loading)
          setVisible(false)
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                  deleteCompanyFilesApi(i.id, i.file)
                  setLoading(!loading)
                  toast.current.show({severity:'success', summary: 'Успешно!', detail: "Документ удален!", life: 3000});
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить заказ?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    return (
        <div className="field">
            <Toast ref={toast} position="bottom-center"/>
            <ConfirmDialog />
            <DataTable filters={filters} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable  paginator rows={25} rowsPerPageOptions={[25, 50, 100]} tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
                <Column body={dateOrder} header="Дата" field="createdAt" style={{width: "17%"}}/>
                <Column header="Фирма" field="companyName" style={{width: "15%"}}/>
                <Column editor={commentEditor} header="Комментарий" field="name"/>
                <Column editor={concludeEditor} header="Подпись" field="conclude" style={{width: "8%", textAlign: 'center'}}/>
                <Column editor={typeEditor} header="Тип" field="type" style={{width: "8%", textAlign: 'center'}}/>
                <Column editor={fileEditor} body={fileView} header="Файл" field="file" style={{width: "2%", textAlign: "center"}}/>
                <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                {user.role === "ADMIN" && <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>}
            </DataTable>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Компания
                        </label>
                        <Dropdown
                            value={companyName}
                            options={companies.filter(i => i.status === "Действующий" || i.status === "Новый" || i.status === "Переговоры").map(i => i.contragent)}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Select a Company"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="comment" className="font-bold">
                            Название
                        </label>
                        <InputText value={name} onChange={e => setName(e.target.value)} id="position"></InputText>
                    </div>  

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Подпись
                        </label>
                        <Dropdown
                            value={conclude}
                            options={["СБИС", "Оригинал", "Скан"]}
                            onChange={(e) => setConclude(e.target.value)}
                            placeholder="Select a Status"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Тип
                        </label>
                        <Dropdown
                            value={type}
                            options={["Акт", "Договор", "Карточка", "Шаблон"]}
                            onChange={(e) => setType(e.target.value)}
                            placeholder="Select a Status"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Файл
                        </label>
                        <input type="file" onChange={e => setFile(e.target.files[0] || null)}/>
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>
        </div>
    )
}