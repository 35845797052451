import React from "react";
import './index.scss'
import { Route, Routes } from "react-router-dom";
import { InformationPrice } from "./price";
import { InformationContacts } from "./contacts";
import { InformationSale } from "./sales";
import { InformationGuides } from "./guides";

export const Information = () => {
    return (
        <div className="field">
            <Routes>
                <Route path="price" element={<InformationPrice/>}/>
                <Route path="contacts" element={<InformationContacts/>}/>
                <Route path="sales" element={<InformationSale/>}/>
                <Route path="guide" element={<InformationGuides/>}/>
            </Routes>
        </div>
    )
}